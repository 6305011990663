import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { DxlShoppingCart, IDXLPostCreateShoppingCartResponse } from './dxlPostCreateShoppingCart.models';

/**
 * @description postCreateShoppingCart, create a cart
 * @returns  {Promise<IDXLPostCreateShoppingCartResponse>}
 */
export const dxlPostCreateShoppingCart = async (payload: DxlShoppingCart.Payloads.ShoppingCart) =>
  await dxlClient.post(
    API.DXL_SHOPPING_CART,
    payload
  ) as Promise<IDXLPostCreateShoppingCartResponse>;

