import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IListLandingPageRequest } from './getListLandingPage.models';

export const getListLandingPage = (payload: IListLandingPageRequest) =>
dxlClient.get(
    API.DXL_GET_LANDING_PAGE,
    {searchParams: {...payload}}
  ) as Promise<any>;

