import {
  BillingObjectDataList,
  DXL,
  IBusinessSolutionInfo,
  IDXLGetProfileResponse,
  IDeviceInfo,
  IEcommerceCatalogResponse,
  IProductInfo,
  IResource,
} from '@vfit/business-data-access';
import { IActionType } from '@vfit/shared/models';
import { QueryClient } from 'react-query';
import crypto from 'crypto-es';
//import { IProductHub } from '@vfit/business-components';
import { TVisitorLoginStatus } from '@vfit/shared/data-access';
import { useContext } from 'react';
import { AuthenticationContext, EAuthType } from '../authentication/authentication';

type IProductHub = any;
export const getProductTrackInformation = (
  queryClient: QueryClient,
  currentValue?: IProductHub
) => {
  const dxlProduct: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
    currentValue?.dxlId,
    'ecommerceCatalogCoreOffer',
  ]);

  const dxlProductWithAddress: IEcommerceCatalogResponse | undefined = queryClient.getQueryData(
    'ecommerceCatalogWithAddress'
  );

  return {
    product_id: [currentValue?.dxlId || ''],
    product_name: [
      currentValue?.taggingProductName || dxlProduct?.productInfo?.[0]?.productName || '',
    ],
    product_category: [
      currentValue?.productType || dxlProduct?.productInfo?.[0]?.productType || '',
    ],
    product_price: [
      currentValue?.taggingProductPrice || getFormattedPriceString(dxlProduct?.productInfo?.[0]),
    ],
    product_technology: [dxlProductWithAddress?.technology || ''],
    product_target_segment: ['business'],
    product_quantity: '1',
  };
};

export const getVisitorTrackInformation = (queryClient: QueryClient) => {
  const { authType } = useContext(AuthenticationContext);

  const { SHA256 } = crypto;
  const visitor: IDXLGetProfileResponse | undefined = queryClient.getQueryData('CBProfile');
  const billingObjActive = visitor?.billingObjectDataList?.find(
    (e: BillingObjectDataList) => e?.status === 'Active'
  );

  return {
    visitor_id_asset_active: getAssetId(billingObjActive),
    visitor_id_asset_primary: getAssetId(billingObjActive),
    visitor_asset_plan_type_active: getPlanType(billingObjActive),
    visitor_id_email: visitor?.profileData?.email
      ? SHA256(visitor?.profileData?.email).toString()
      : '',
    visitor_login_status: getLoginStatus(authType),
    visitor_type: authType,
    visitor_customer_type: 'business',
    visitor_login_authorisation_role: getProfileRole(visitor?.profileData?.profile),
    visitor_customer_segment: visitor?.assetData?.segment || '',
  };
};

const getAssetId = (billingObjActive?: BillingObjectDataList) => {
  const { SHA256 } = crypto;
  if (billingObjActive) {
    return billingObjActive?.subscriptionName === 'SIM_MOBILE'
      ? SHA256(billingObjActive?.msisdn).toString()
      : SHA256(billingObjActive?.cli).toString();
  }
  return '';
};

const getProfileRole = (profile?: string) => {
  if (profile) {
    if (profile === 'BUSINESS_MASTER_REFERENT' || profile === 'BUSINESS_OPERATIVE_REFERENT')
      return 'AccountManager';
    return 'EndUser';
  }
  return '';
};

const getPlanType = (obj?: BillingObjectDataList) => {
  if (obj) {
    if (obj?.subscriptionName !== 'SIM_MOBILE') return 'fixed';
    if (obj?.paymentType === 'Prepaid') return 'payg';
    return 'paym';
  }
  return '';
};

const getLoginStatus = (authType: EAuthType): TVisitorLoginStatus => {
  if (authType === EAuthType.CB) return 'logged in';
  return 'logged out';
};

export const getFormattedPriceString = (dxlProduct?: IProductInfo | IResource): string => {
  if (
    dxlProduct?.costs?.renewalCost! > 0 &&
    (!dxlProduct?.costs?.discountedRenewalCost || dxlProduct?.costs.discountedRenewalCost === 0)
  )
    return dxlProduct?.costs?.renewalCost?.toFixed(2) || '';
  if (dxlProduct?.costs?.renewalCost! > 0 && dxlProduct?.costs?.discountedRenewalCost! > 0)
    return dxlProduct?.costs?.discountedRenewalCost?.toFixed(2) || '';

  return '';
};

export const getFormattedPriceStringDS = (costs?: Partial<DXL.ICost>): string => {
  if (
    costs?.renewalCost &&
    costs?.renewalCost > 0 &&
    (!costs?.discountedRenewalCost || costs.discountedRenewalCost === 0)
  )
    return costs?.renewalCost?.toFixed(2) || '';
  if (
    costs?.renewalCost &&
    costs?.renewalCost > 0 &&
    costs?.discountedRenewalCost &&
    costs?.discountedRenewalCost > 0
  )
    return costs?.discountedRenewalCost?.toFixed(2) || '';

  if (
    costs?.renewalCost === 0 &&
    costs?.activationCost &&
    costs?.activationCost > 0 &&
    !costs?.discountedActivationCost
  )
    return costs?.activationCost?.toFixed(2) || '';

  if (
    costs?.renewalCost === 0 &&
    costs?.activationCost &&
    costs?.activationCost > 0 &&
    costs?.discountedActivationCost &&
    costs?.discountedActivationCost > 0
  )
    return costs?.discountedActivationCost?.toFixed(2) || '';

  return '';
};

export const getEventLabel = (type?: IActionType) => {
  const actionType = type ? +type : 0;
  switch (actionType) {
    case IActionType.LOCAL_URL:
      return ':Scopri di piu';
    case IActionType.CALL_ME_NOW:
      return ':CTC';
    case IActionType.COVERAGE_TOOL:
      return ':Verifica copertura';
    case IActionType.CHECKOUT_MODAL_MOBILE:
      return ':Attiva ora';
    default:
      return '';
  }
};

export const getCoreOfferInformation = (
  queryClient: QueryClient,
  allProducts?: IProductHub[],
  selectedProduct?: any
) => {
  let productsInformation = [];

  if (allProducts) {
    productsInformation = allProducts?.reduce((acc: any, currentValue: IProductHub) => {
      const productTrackingInformation = getProductTrackInformation(queryClient, currentValue);
      return {
        product_id: [...(acc?.product_id || []), ...productTrackingInformation?.product_id!],
        product_name: [...(acc?.product_name || []), ...productTrackingInformation?.product_name!],
        product_category: [
          ...(acc?.product_category || []),
          ...productTrackingInformation?.product_category!,
        ],
        product_price: [
          ...(acc?.product_price || []),
          ...productTrackingInformation?.product_price!,
        ],
        product_target_segment: [
          ...(acc?.product_target_segment || []),
          ...productTrackingInformation?.product_target_segment!,
        ],
        product_technology: [
          ...(acc?.product_technology || []),
          ...productTrackingInformation?.product_technology!,
        ],
        product_quantity: allProducts?.length.toString(),
      } as any;
    }, {});
  } else if (selectedProduct) {
    const productTrackingInformation = getProductTrackInformation(queryClient, selectedProduct);
    productsInformation = {
      product_id: productTrackingInformation?.product_id!,
      product_name: productTrackingInformation?.product_name!,
      product_category: productTrackingInformation?.product_category!,
      product_price: productTrackingInformation?.product_price!,
      product_target_segment: productTrackingInformation?.product_target_segment!,
      product_technology: productTrackingInformation?.product_technology,
      product_quantity: '1',
    } as any;
  }

  return productsInformation;
};

export const getDeviceTrackInformation = (
  queryClient: QueryClient,
  currentValue?: IDeviceInfo,
  selectedResource?: IResource
) => {
  const dxlDevice: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
    currentValue?.deviceId,
    'ecommerceCatalogDeviceInfo',
  ]);
  const device = dxlDevice?.deviceInfo?.[0] || currentValue;

  return selectedResource
    ? {
        product_id: [
          device?.deviceId || '',
          selectedResource?.product?.[0]?.productSpecificationId || '',
        ],
        product_name: [device?.deviceName || '', selectedResource?.product?.[0]?.productName || ''],
        product_category: [
          device?.resourceCategoryDescription || '',
          selectedResource?.product?.[0]?.productType || '',
        ],
        product_price: [
          getFormattedPriceString(selectedResource),
          getFormattedPriceString(selectedResource?.product?.[0]),
        ],
        product_target_segment: ['business', 'business'],
        product_quantity: '1',
      }
    : {
        product_id: [device?.deviceId || ''],
        product_name: [device?.deviceName || ''],
        product_category: [device?.resourceCategoryDescription || ''],
        product_price: [device?.lowerCost!],
        product_target_segment: ['business'],
        product_quantity: '1',
      };
};

export const getDeviceInformation = (
  queryClient: QueryClient,
  isAllProducts?: boolean,
  selectedProduct?: any
) => {
  let productsInformation = [];

  if (isAllProducts) {
    const deviceCatalog = queryClient.getQueryData(
      'ecommerceCatalogDevices'
    ) as IEcommerceCatalogResponse;
    const devices = deviceCatalog?.deviceInfo;

    productsInformation = devices?.reduce((acc: any, currentValue: IDeviceInfo) => {
      const productTrackingInformation = getDeviceTrackInformation(queryClient, currentValue);
      return {
        product_id: [...(acc?.product_id || []), ...productTrackingInformation?.product_id!],
        product_name: [...(acc?.product_name || []), ...productTrackingInformation?.product_name!],
        product_category: [
          ...(acc?.product_category || []),
          ...productTrackingInformation?.product_category!,
        ],
        product_price: [
          ...(acc?.product_price || []),
          ...productTrackingInformation?.product_price!,
        ],
        product_target_segment: [
          ...(acc?.product_target_segment || []),
          ...productTrackingInformation?.product_target_segment!,
        ],
        product_quantity: devices?.length.toString(),
      } as any;
    }, {});
  } else if (selectedProduct) {
    const productTrackingInformation = getDeviceTrackInformation(queryClient, selectedProduct);
    productsInformation = {
      product_id: productTrackingInformation?.product_id!,
      product_name: productTrackingInformation?.product_name!,
      product_category: productTrackingInformation?.product_category!,
      product_price: productTrackingInformation?.product_price!,
      product_target_segment: productTrackingInformation?.product_target_segment!,
      product_quantity: '1',
    } as any;
  }
  return productsInformation;
};

export const getDigitalSolutionTrackInformation = (
  queryClient: QueryClient,
  currentValue?: any,
  editionInfoId?: string
) => {
  const dxlDigitalSolution: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
    currentValue?.dxlId,
    'ecommerceCatalogDSOffer',
  ]);

  const digitalSolution = dxlDigitalSolution?.businessSolutionInfo?.[0] || currentValue;

  const isMultipleDS = !!currentValue.multipleDS;

  if (isMultipleDS) {
    const elements = editionInfoId
      ? currentValue?.multipleDS?.elements.filter((item: any) => item.dxlId === editionInfoId)
      : currentValue?.multipleDS?.elements;
    const totalEditionInfo = elements?.reduce((acc: any, current: IProductHub) => {
      const currentEditionInfo = dxlDigitalSolution?.businessSolutionInfo?.[0]?.editionInfo?.find(
        (item) => item.productSpecificationId === current.dxlId
      );

      return {
        product_id: [...(acc?.product_id || []), current.dxlId || ''],
        product_name: [
          ...(acc?.product_name || []),
          `${digitalSolution?.overrideOfferName || digitalSolution?.businessSolutionName} - ${
            current.overrideOfferName || currentEditionInfo?.productName || ''
          }`,
        ],
        product_category: [
          ...(acc?.product_category || []),
          currentEditionInfo?.productType || 'DS',
        ],
        product_price: [
          ...(acc?.product_price || []),
          current.taggingProductPrice || getFormattedPriceStringDS(currentEditionInfo?.costs),
        ],
        product_target_segment: [...(acc?.product_target_segment || []), 'business'],
        product_quantity: elements?.length.toString(),
      } as any;
    }, {});

    return totalEditionInfo;
  }

  return {
    product_id: [digitalSolution?.dxlId || digitalSolution?.businessSolutionId || ''],
    product_name: [
      digitalSolution?.overrideOfferName || digitalSolution?.businessSolutionName || '',
    ],
    product_category: [digitalSolution?.editionInfo?.[0]?.productType || 'DS'],
    product_price: [
      digitalSolution?.taggingProductPrice ||
        getFormattedPriceStringDS(digitalSolution?.editionInfo?.[0]?.costs),
    ],
    product_target_segment: ['business'],
    product_quantity: '1',
  };
};

export const getDigitalSolutionInformation = (
  queryClient: QueryClient,
  isAllProducts?: boolean,
  selectedProduct?: any
) => {
  let productsInformation = [];

  if (isAllProducts) {
    const digitalSolutionCatalog = queryClient.getQueryData(
      'ecommerceCatalogDigitalSolutions'
    ) as IEcommerceCatalogResponse;
    const digitalSolutions = digitalSolutionCatalog?.businessSolutionInfo;

    productsInformation = digitalSolutions?.reduce(
      (acc: any, currentValue: IBusinessSolutionInfo) => {
        const productTrackingInformation = getDigitalSolutionTrackInformation(
          queryClient,
          currentValue
        );
        return {
          product_id: [...(acc?.product_id || []), ...productTrackingInformation.product_id],
          product_name: [...(acc?.product_name || []), ...productTrackingInformation.product_name],
          product_category: [
            ...(acc?.product_category || []),
            ...productTrackingInformation.product_category,
          ],
          product_target_segment: [
            ...(acc?.product_target_segment || []),
            ...productTrackingInformation.product_target_segment,
          ],
          product_quantity: digitalSolutions?.length.toString(),
        } as any;
      },
      {}
    );
  } else if (selectedProduct) {
    const productTrackingInformation = getDigitalSolutionTrackInformation(
      queryClient,
      selectedProduct
    );
    productsInformation = {
      product_id: productTrackingInformation?.product_id || '',
      product_name: productTrackingInformation?.product_name || '',
      product_category: productTrackingInformation?.product_category || '',
      product_price: productTrackingInformation?.product_price || '',
      product_target_segment: productTrackingInformation?.product_target_segment || '',
      product_quantity: productTrackingInformation?.product_quantity || '',
    } as any;
  }
  return productsInformation;
};

