import { dxlCloudClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import {
  IVerifyRetrieveDataRequest,
  IVerifyRetrieveDataResponse,
} from './verifyRetrieveData.models';

export const postVerifyRetrieveData = (payload: IVerifyRetrieveDataRequest) =>
  dxlCloudClient.post(
    API.AWS_VERIFY_RETRIEVE_DATA,
    payload
  ) as Promise<IVerifyRetrieveDataResponse>;

