import { useContext } from 'react';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { useMutation, useQueryClient } from 'react-query';
import { HTTPError } from 'ky';
import { PAGES } from '@vfit/shared/data-access';
import { postSendOtp } from '../../api/sendOtp/sendOtp';
import { ISendOtpRequest, ISendOtpResponse } from '../../api';
import { ErrorObj, SendOtpErrorMessage } from './awsHooks.models';

const useSendOtp = (
  setHandleOtpIsLoading: (loading: boolean) => void,
  setErrorDetail: (error: ErrorObj) => void
) => {
  const { setIsLoading } = useContext(PageLoadingContext);
  const queryClient = useQueryClient();

  const { data, mutate } = useMutation(
    'awsSendOtp',
    (payload: ISendOtpRequest) => {
      setHandleOtpIsLoading(true);
      return postSendOtp(payload);
    },
    {
      onSuccess: (res: ISendOtpResponse) => {
        queryClient.setQueryData('msisdn', res.msisdn);
        setHandleOtpIsLoading(false);
        setIsLoading(false);
      },
      onError: async (error: HTTPError) => {
        const resp = (await error.response.json().catch(() => ({
          code: 'generic-error',
          message: 'generic-error',
        }))) as {};
        const foundMessage = Object.values(SendOtpErrorMessage).find((message) =>
          resp?.['message']?.includes(message)
        );
        if (!foundMessage) {
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorPage: PAGES.GENERIC_ERROR,
            message: 'generic-error',
          });
        } else {
          const errorResp = { ...resp, message: foundMessage };
          setErrorDetail(errorResp);
          setHandleOtpIsLoading(false);
          setIsLoading(false);
        }
      },
    }
  );

  return {
    sendResendOtp: (payload: ISendOtpRequest) => mutate(payload),
    data,
  };
};

export { useSendOtp };

