import { API, LoggerInstance } from '@vfit/shared/data-access';
import { handleEnv } from '@vfit/shared/data-access';
import { isDevLocal } from '../../cms/staticPathPages/staticPathPages.utils';

const FIXED_OFFER_LIST = isDevLocal
  ? `${API.CMS_BFL_GET_PRODUCTS}`
  : `${API.CMS_BFL_GET_PRODUCTS}.json`;

/**
 * Retrieve Fixed line visibility offers from AEM
 */
const getFixedLineProductSlug = async (): Promise<Array<{ params: { slug: string } }>> => {
  try {
    const endpoint = `${handleEnv('NEXT_PUBLIC_CMS')}/${FIXED_OFFER_LIST}`;
    const allProductRes = await fetch(endpoint);
    const allProductId = await allProductRes.json();
    const allSlugs: string[] = [];
    Object.values(allProductId).map((el: any) => {
      if (el?.widgetProductBanner?.product?.slug)
        allSlugs.push(el?.widgetProductBanner?.product?.slug);
    });

    return allSlugs.map((fileName) => ({
      params: {
        slug: fileName.replace(/\.md$/, ''),
      },
    }));
  } catch (e) {
    LoggerInstance.error('##   getFixedLineProductSlug  ## ');

    return [];
  }
};

export { getFixedLineProductSlug };

