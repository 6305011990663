import { ErrorAction, errorManager, ErrorSeverity, useHandleQueryWithAuthSSO } from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getListLandingPage } from '../../api';

interface IGetListLandingPage {
  version: string;
  code: string;
  enabled: boolean;
  handleNextStep: () => void;
}

interface IGetListLandingPageResponse {
  error: Response;
}

export const useGetListLandingPage = ({
  code,
  version,
  enabled,
  handleNextStep,
}: IGetListLandingPage): IGetListLandingPageResponse => {
  const getListLanding = {
    queryKey: 'listLandingPage',
    queryFn: () => {
      return getListLandingPage({ code, version });
    },
    options: {
      onSuccess: () => handleNextStep(),
      onError: (error) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        if (statusNumber !== 404) {
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorCode: statusNumber,
          });
        }
      },
      enabled: enabled,
    },
  } as UseQueryConfig;

 const error = useHandleQueryWithAuthSSO(getListLanding).error as Response;
  return {
    error
  };
};

