import { useState, isValidElement } from 'react';
import { ChevronDown } from '@vfit/shared-icons';
import { AccordionNavigationType } from '@vfit/shared/models';
import {
  StyledAccordion,
  Title,
  TitleText,
  IconSpan,
  SubtitleText,
  ContentText,
} from './accordion.style';
import { IAccordionChild, IAccordionProps } from './accordion.models';

const Accordion = (props: IAccordionProps) => {
  const {
    title,
    content,
    background,
    padding,
    borderColor,
    textColor,
    fontFamily,
    fontSize,
    fontWeight,
    iconColor,
    iconSize,
    highlightColor,
    enableHighlight,
    accordionNavigationType = AccordionNavigationType.MENU,
    lineHeight,
  } = props;
  const [isShowing, setIsShowing] = useState(false);

  const handleClick = () => {
    setIsShowing((prevState) => !prevState);
  };

  const contentAccordion = () =>
    content.map((item, index) => (
      <>
        {isValidElement(item) && item}
        {!isValidElement(item) && (
          <div key={`acc${index + 1}`}>
            {(item as IAccordionChild).subtitle && (
              <SubtitleText>{(item as IAccordionChild).subtitle}</SubtitleText>
            )}
            <ContentText>{(item as IAccordionChild).text}</ContentText>
          </div>
        )}
      </>
    ));

  return (
    <StyledAccordion
      background={background}
      padding={padding}
      borderColor={borderColor}
      isMenu={accordionNavigationType === AccordionNavigationType.MENU}
    >
      <Title onClick={handleClick}>
        <TitleText
          isMenu={accordionNavigationType === AccordionNavigationType.MENU}
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={isShowing && enableHighlight ? highlightColor : textColor}
        >
          {title}
        </TitleText>
        <IconSpan
          color={isShowing && enableHighlight ? highlightColor : iconColor}
          size={iconSize}
          className={` ${isShowing ? 'showing' : ''} `}
        >
          <ChevronDown />
        </IconSpan>
      </Title>
      <div style={{display: isShowing ? 'contents' : 'none'}}>
        {contentAccordion()}
      </div>
    </StyledAccordion>
  );
};

export default Accordion;
