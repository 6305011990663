import { useVolaUtils } from '@vfit/business-data-access';
import { AuthenticationContext, TrackingPageContext } from '@vfit/business-data-access';
import { IDatalayer, iFrameManager } from '@vfit/shared/data-access';
import { IGetAllCmsForm, manageErrorLeadPlatform, manageSuccessLeadPlatform, openLeadModal } from '@vfit/shared/data-access';
import { IActionType, IActionSlide, ISubmitOutput, IErrorFormApi } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { IProductCard } from '../ProductCard/productCard.models';

export const useWidgetsAction = () => {
  const { push, back } = useRouter();
  const { formatUrlVola, openPopupVola, formatDynamicVolaUrl } = useVolaUtils();
  const queryClient = useQueryClient();
  const { descriptionVolaUrl, link, currentDataLayer } = useContext(TrackingPageContext);
  const { authType } = useContext(AuthenticationContext);

  let activeLayer: IDatalayer = {
    event_name: [],
  };

  const handleClick = (param?: IActionSlide, setActions?: any, volaDesc?: string, product?: IProductCard) => {
    const type = param?.type ? +param.type : 0;

    switch (type) {
      case IActionType.CUSTOM_URL:
        if (param?.url) window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        break;
      case IActionType.LOCAL_URL:
        if (param?.url) push(param?.url);
        break;
      case IActionType.RETRY:
        back();
        break;
      case IActionType.WHATSAPP_URL:
        setActions?.whatsapp?.(true)
        break;
      case IActionType.COVERAGE_TOOL:
        setActions?.coverage?.(true)
        break;
      case IActionType.CALL_ME_NOW:
        if (param?.url) {
          if (descriptionVolaUrl && volaDesc)
            openPopupVola(formatDynamicVolaUrl(param?.url, volaDesc), 'vola-ctc');
          else openPopupVola(formatUrlVola(param?.url, product as IProductCard), 'vola-ctc');
        }
        break;
       case IActionType.IFRAME_URL:
        if (!param?.url) return;
        // add tracking
        Object.keys(currentDataLayer).forEach((key) => {
          if (currentDataLayer[key].event_name.length > 0) activeLayer = currentDataLayer[key];
        });
        link({
          ...activeLayer,
          link_name: `${activeLayer.page_name}:CTC`,
        });
        iFrameManager.handleIFrame({
          url: param?.url,
          id: 'custom_iframe'
        });
        break;
      case IActionType.LEAD_MODAL:
        if (queryClient) {
          const allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
          // form name
          const ref = param?.url || '';
          const forms = allLeads?.[ref].forms;
          if (forms) {
            openLeadModal({
              form: forms,
              onSuccess: (submitOutput: ISubmitOutput) =>
                manageSuccessLeadPlatform(submitOutput, push, queryClient),
              onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
                manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
              trackingOption: {
                visitor_type: authType
              },
            });
          }
        }
        break;
      default:
        break;
    }
  };

  return {
    handleClick,
  };
};

