import { useMutation, useQueryClient } from 'react-query';
import {
  errorManager,
  ErrorAction,
  ErrorSeverity,
  useHandleQueryWithAuthSSO,
  PageLoadingContext
} from '@vfit/business-data-access';
import { IInputsCoverageTool } from '@vfit/shared/models';
import { useContext, useEffect } from 'react';
import { API, PAGES } from '@vfit/shared/data-access';
//import { IAddonId, IOfferDetail } from '@vfit/business-components';
import {
  DXL,
  DeepPartial,
  DxlShoppingCart,
  IDXLGetProfileResponse,
  WebShoppingCart,
  IEcommerceCatalogResponse,
  IResource,
  dxlPostCreateShoppingCart,
} from '../../api';
import { CMS_CONFIG, useCmsConfig } from '../../cms';

const getContactMediumFromShoppingCart = (address?: IInputsCoverageTool) => {
  const contactMedium: Partial<DXL.IContactMedium>[] = [];
  if (address) {
    contactMedium.push({
      '@type': 'GeographicAddress',
      mediumType: 'installation address',
      preferred: true,
      characteristic: {
        city: address?.city,
        postCode: address?.postalCode,
        socialNetworkId: address?.placeId,
        stateOrProvince: address?.stateOrProvince,
        street1: `${address?.street}|${address?.streetNumber}`,
      } as DXL.IContactMediumCharacteristic,
    });
  }
  return contactMedium;
};

const mapWebCartItemToDxlCartItem = (
  cartItem?: IEcommerceCatalogResponse,
  selectedAddons?: {
    addonList?: any[];
    power4gAddon?: any[];
  }
) => {
  const { productInfo } = cartItem || {};
  const deviceInfo = cartItem?.deviceInfo as WebShoppingCart.IDevice[];
  const requestCartItem: DeepPartial<DxlShoppingCart.ICartItem> = {
    status: 'active',
    quantity: 1,
  };
  const product = {} as DxlShoppingCart.ICartItemProduct;

  requestCartItem.productOffering = {
    id: productInfo?.[0]?.productOfferingId,
    '@referredType': productInfo?.[0]?.productType,
  };

  product.productSpecification = {
    id: productInfo?.[0]?.productSpecificationId!,
    name: productInfo?.[0]?.productName!,
  };

  product.productCharacteristic = [
    {
      name: 'category',
      value: productInfo?.[0]?.productCategory,
    },
  ];

  //Add "version" or "MNP" or "GNP"
  if(productInfo?.[0]?.costs?.version) {
    product.productCharacteristic.push({
      name: "version", //"version" or "MNP" or "GNP"
      value: productInfo?.[0]?.costs?.version, // price version or true
    });
  }

  //Add "timeDiscount"
  if(productInfo?.[0]?.timeDiscount) {
    product.productCharacteristic.push({
      name: "timediscount",
      value: productInfo?.[0]?.timeDiscount, // timeDiscount of offer
    });
  }

  //Add mandatory addon
  product.product = [];
  // product.product = productInfo?.[0]?.addOn?.filter(a => a?.productType === 'M')?.map(addon => {
  //   return {
  //     productSpecification: {
  //       id: addon.productSpecificationId,
  //       name: addon?.productName,
  //       '@referredType': 'AddOn',
  //     },
  //   } as DxlShoppingCart.ICartItemProductProduct;
  // })!

  // Add selected AddOns not mandatory
  const addOnNotM = productInfo?.[0]?.addOn?.filter((addon) => addon?.productType !== 'M'); //change check: if productType is 'O' 'C' or 'D'
  if (selectedAddons && addOnNotM) {
    const otherAddon = addOnNotM
      ?.filter((a) => selectedAddons?.addonList?.find((e) => e?.id === a?.productSpecificationId))
      ?.map((addon) => {
        return {
          productSpecification: {
            id: addon.productSpecificationId,
            name: addon?.productName,
            '@referredType': 'AddOn',
          },
        } as DxlShoppingCart.ICartItemProductProduct;
      })!;

    product.product = [...product.product, ...otherAddon];

    //add addon power4g
    if (selectedAddons?.power4gAddon && productInfo?.[0]?.technicalPacket?.['4G']) {
      const addon4g = addOnNotM?.find(
        (a) => a.productSpecificationId === selectedAddons?.power4gAddon?.[0]?.id
      );
      if (addon4g) {
        product.product.push({
          productSpecification: {
            id: addon4g?.productSpecificationId,
            name: addon4g?.productName,
            '@referredType': 'AddOn',
          },
        } as DxlShoppingCart.ICartItemProductProduct);
      }
    }
  }

  if (productInfo?.[0]?.technicalPacket) {
    product.product.push({
      realizingService: [
        {
          id: productInfo?.[0]?.technicalPacket.id,
          name: productInfo?.[0]?.technicalPacket.mainTech,
          characteristic: [
            { name: '4G', value: productInfo?.[0]?.technicalPacket['4G'] ? 'Y' : 'N' },
          ],
        },
      ],
    } as DxlShoppingCart.ICartItemProductProduct);
  }

  //tariffPlan
  if (productInfo?.[0]?.tariffPlan) {
    product.product.push({
      productSpecification: {
        id: productInfo?.[0]?.tariffPlan?.productSpecificationId!,
        name: productInfo?.[0]?.tariffPlan?.productName!,
        '@referredType': 'tariffPlan',
      },
    } as DxlShoppingCart.ICartItemProductProduct);
  }

  //TODO: manage businessSolutions

  // Add devices e.b
  if(deviceInfo?.length > 0) {
    const selectedDevices = deviceInfo?.map(device => {
      const resource = device.selectedResource;
      const colour = resource.resourceColour?.id||'';
      const capacity = resource.resourceCapacity?.id||'';
      //[ECO-5669] <deviceId>|<deviceBrand> <deviceModel> <colour>, <capacity>
      const name = `${device.deviceId||''}|${device.deviceBrand||''} ${device.deviceName||''} ${colour} ${capacity}`;
      return {
        realizingResource: [{
          id: resource.resourceCandidateId,
          name,
          '@referredType': 'PhysicalResourceSpec'
        }]
      } as DxlShoppingCart.ICartItemProductProduct
    })!;
    product.product.push(...selectedDevices);
  }

  requestCartItem.product = product;
  return [requestCartItem];
};

const mapWebCartItemToDxlCartItemDS = (cartItem?: IEcommerceCatalogResponse) => {
  const { businessSolutionInfo } = cartItem || {};
  const requestCartItem: DeepPartial<DxlShoppingCart.ICartItem> = {
    status: 'active',
    quantity: 1,
  };
  const product = {} as DxlShoppingCart.ICartItemProduct;

  const bsEditionInfo = businessSolutionInfo?.[0]?.editionInfo;

  product.product = [];

  requestCartItem.productOffering = {
    id: bsEditionInfo?.[0]?.productOfferingId,
    '@referredType': bsEditionInfo?.[0]?.productType,
  };

  product.productSpecification = {
      id: bsEditionInfo?.[0].productSpecificationId || '',
      name: bsEditionInfo?.[0].productName || '',
  };

  product.productCharacteristic = [
    {
      name: 'category',
      value: bsEditionInfo?.[0].productCategory,
    },
  ];

  requestCartItem.product = product;
  return [requestCartItem];
};

const usePostCreateShoppingCart = () => {
  //set loading
  const { setIsLoading } = useContext(PageLoadingContext);

  const queryClient = useQueryClient();
  //retrive profile data from react query
  const profile: IDXLGetProfileResponse | undefined = queryClient.getQueryData('CBProfile');

  //retrive cartItem data from react query
  const cartItems: IEcommerceCatalogResponse | undefined =
    queryClient.getQueryData('ecommerceCatalogWithAddress') ||
    JSON.parse(localStorage.getItem('catalog_address') || '');

  //retrive selected address
  const coverageAddress: IInputsCoverageTool | undefined = queryClient.getQueryData('validAddress');

  // retrive selected addons
  const productDetailsAEM = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_DETAIL_PRODUCTS
  ) as any;
  const selectedaddons =
    productDetailsAEM?.[cartItems?.productInfo?.[0]?.productSpecificationId!]?.selectedaddons;

  //create paylod for postShoppingCart API
  const payload: DxlShoppingCart.Payloads.ShoppingCart = {
    cartItem: mapWebCartItemToDxlCartItem(cartItems, selectedaddons),
    contactMedium: getContactMediumFromShoppingCart(coverageAddress),
    relatedParty: [],
    validFor: {
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
    },
  };

  //check if user is CB or PROSPECT
  if (profile?.profileData && !!profile?.profileData.username) {
    payload.relatedParty!.push({
      id: profile?.profileData.username,
      role: 'web account',
    });
  }

  //create query
  const postCreateShoppingCart = {
    queryKey: 'createShoppingCart',
    queryFn: async () => dxlPostCreateShoppingCart(payload),
    options: {
      onError: (error: any) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
      enabled: cartItems ? true : false,
    },
  };

  //retrive response
  const queryResult = useHandleQueryWithAuthSSO(postCreateShoppingCart);
  const { data, isError } = queryResult;
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queryResult]);

  return {
    data,
    isError,
  };
};

const usePostCreateShoppingCartMobile = (offerId: string, showCheckout: string) => {
  const queryClient = useQueryClient();
  //retrive profile data from react query
  const profile: IDXLGetProfileResponse | undefined = queryClient.getQueryData('CBProfile');

  //retrive cartItem data from react query
  const cartItems: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
    offerId,
    'ecommerceCatalogCoreOffer',
  ]);

  //create paylod for postShoppingCart API
  const payload: DxlShoppingCart.Payloads.ShoppingCart = {
    cartItem: mapWebCartItemToDxlCartItem(cartItems),
    contactMedium: getContactMediumFromShoppingCart(),
    relatedParty: [],
    validFor: {
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
    },
  };

  //check if user is CB or PROSPECT
  if (profile?.profileData && !!profile?.profileData.username) {
    payload.relatedParty!.push({
      id: profile?.profileData.username,
      role: 'web account',
    });
  }

  // create query
  const postCreateShoppingCart = {
    queryKey: [offerId, 'createShoppingCartMobile'],
    queryFn: async () => dxlPostCreateShoppingCart(payload),
    options: {
      onError: (error: any) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
      enabled: showCheckout === offerId,
    },
  };

  //retrive response
  const queryResult = useHandleQueryWithAuthSSO(postCreateShoppingCart);
  const { data } = queryResult;

  return {
    data,
  };
};

const usePostCreateShoppingCartDevice = (deviceId: string, resource: IResource, showCheckOut: boolean) => {

  const queryClient = useQueryClient();
  //retrive profile data from react query
  const profile: IDXLGetProfileResponse | undefined = queryClient.getQueryData('CBProfile');

  //retrive device data from react query
  const deviceItem: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
    deviceId,
    'ecommerceCatalogDeviceInfo',
  ]);

  const cartItems = {
    productInfo: resource?.product || [],
    deviceInfo: [{
      ...deviceItem?.deviceInfo?.[0],
      selectedResource: resource
    }]
  }

  //create paylod for postShoppingCart API
  const payload: DxlShoppingCart.Payloads.ShoppingCart = {
    cartItem: mapWebCartItemToDxlCartItem(cartItems),
    contactMedium: getContactMediumFromShoppingCart(),
    relatedParty: [],
    validFor: {
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
    },
  };

  //check if user is CB or PROSPECT
  if (profile?.profileData && !!profile?.profileData.username) {
    payload.relatedParty!.push({
      id: profile?.profileData.username,
      role: 'web account',
    });
  }

  //create query
  const postCreateShoppingCart = {
    queryKey: 'createShoppingCartOfferAndDevice',
    queryFn: async () => dxlPostCreateShoppingCart(payload),
    options: {
      onError: (error: any) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
      enabled: showCheckOut,
    },
  };

  //retrive response
  const queryResult = useHandleQueryWithAuthSSO(postCreateShoppingCart);
  const { data, isError } = queryResult;

  return {
    data,
    isError,
  };
};

const usePostCreateShoppingCartDSMutation = (offerId: string, selectedId?: string) => {
  const queryClient = useQueryClient();

  // retrive profile data from react query
  const profile: IDXLGetProfileResponse | undefined = queryClient.getQueryData('CBProfile');

  // retrive cartItem data from react query
  const cartItems: IEcommerceCatalogResponse | undefined = queryClient.getQueryData([
    offerId,
    'ecommerceCatalogDSOffer',
  ]);

  const getCartItem = (): IEcommerceCatalogResponse | undefined => !selectedId ? cartItems : {
    businessSolutionInfo: [{
      ...cartItems?.businessSolutionInfo?.[0],
      editionInfo: [cartItems?.businessSolutionInfo?.[0].editionInfo?.find(info => info.productSpecificationId === selectedId) || {}]
    }]
  };

  // create paylod for postShoppingCart API
  const payload: DxlShoppingCart.Payloads.ShoppingCart = {
    cartItem: mapWebCartItemToDxlCartItemDS(getCartItem()),
    contactMedium: getContactMediumFromShoppingCart(),
    relatedParty: [],
    validFor: {
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
    },
  };

  // check if user is CB or PROSPECT
  if (profile?.profileData && !!profile?.profileData.username) {
    payload.relatedParty!.push({
      id: profile?.profileData.username,
      role: 'web account',
    });
  }

  const { data, mutate } = useMutation(
    'shoppingCartDS',
    () => dxlPostCreateShoppingCart(payload),
    {
      onError: (error: any) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
    }
  );

  return { createShoppingCart: () => mutate(), data }
};

export {
  usePostCreateShoppingCart,
  usePostCreateShoppingCartMobile,
  usePostCreateShoppingCartDevice,
  usePostCreateShoppingCartDSMutation
};
