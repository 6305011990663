import { useContext } from 'react';
import { dxlGetProfile } from '../../api';
import { AuthenticationContext, EAuthType, useHandleQueryWithAuthSSO } from '../../providers/authentication/authentication';

const useGetProfile = () => {
  const { authType } = useContext(AuthenticationContext);

  const getProfileQuery = {
    queryKey: 'CBProfile',
    queryFn: async () => dxlGetProfile(),
    options: {
      enabled: authType === EAuthType.CB,
      onError: (error: any) => {
        console.log('auth flow: Hub Page --> getProfileQuery --> onError', error);
      },
    },
  };

  const { data: dataProfile } = useHandleQueryWithAuthSSO(getProfileQuery);

  return {
    dataProfile,
  };
};

export { useGetProfile };

