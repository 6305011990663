import { useContext } from 'react';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { useMutation, useQueryClient } from 'react-query';
import { HTTPError } from 'ky';
import { PAGES } from '@vfit/shared/data-access';
import { OtpCustomDxlErrorMessage, ErrorObj, SendOtpErrorMessage } from './awsHooks.models';
import {
  ISendConfirmationOtpRequest,
  ISendConfirmationOtpResponse,
  postSendConfirmationOtp,
} from '../../api/sendConfirmationOtp';

const useSendConfirmationOtp = (
  setHandleOtpIsLoading: (loading: boolean) => void,
  setErrorDetail: (error: ErrorObj) => void
) => {
  const { setIsLoading } = useContext(PageLoadingContext);
  const queryClient = useQueryClient();

  const { data, mutate } = useMutation(
    'awsSendConfirmationOtp',
    (payload: ISendConfirmationOtpRequest) => {
      setHandleOtpIsLoading(true);
      return postSendConfirmationOtp(payload);
    },
    {
      onSuccess: (res: ISendConfirmationOtpResponse) => {
        queryClient.setQueryData('msisdn', res.msisdn);
        setHandleOtpIsLoading(false);
        setIsLoading(false);
      },
      onError: async (error: HTTPError) => {
        const resp = (await error.response.json().catch(() => ({
          code: 'generic-error',
          message: 'generic-error',
        }))) as {};
        const foundMessage = Object.values(SendOtpErrorMessage).find((message) =>
          resp?.['message']?.includes(message)
        );
        const otpCustomDxlMessage = Object.values(OtpCustomDxlErrorMessage).find((message) =>
          resp?.['message']?.includes(message)
        );
        if (!foundMessage && !otpCustomDxlMessage) {
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorPage: PAGES.GENERIC_ERROR,
          });
        } else {
          const errorResp = { ...resp, message: foundMessage || otpCustomDxlMessage };
          setErrorDetail(errorResp);
          setHandleOtpIsLoading(false);
          setIsLoading(false);
        }
      },
    }
  );

  return {
    sendResendConfirmationOtp: (payload: ISendConfirmationOtpRequest) => mutate(payload),
    data,
  };
};

export { useSendConfirmationOtp };

