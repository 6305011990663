import {
  ICMSBusinessPageParams,
  ICMSPage,
  ICMSWidgetType,
  IPagesType,
  IStaticPathPagesPaths,
  ProductType,
} from '@vfit/business-data-access';
import { handleEnv } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';

const MAX_NB_RETRY = 5;
const RETRY_DELAY_MS = 800;
// const isDevLocal = handleEnv('NODE_ENV') === 'development' || handleEnv('NODE_ENV') === 'prebuild';

const isDevLocal = handleEnv('NEXT_PUBLIC_CMS_ENV') === 'LOCAL';

const authDisabledLayout = ['APPOINTMENT'];

export default async function fetchRetry(input: string, init?: RequestInit | undefined) {
  let retryLeft = MAX_NB_RETRY;
  while (retryLeft > 0) {
    try {
      return await fetch(input, init);
    } catch (err) {
      console.log(`Retry:${input}`);
      await delay(RETRY_DELAY_MS);
    } finally {
      retryLeft -= 1;
    }
  }
  throw new Error(`Too many retries ${input}`);
}

export const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const getPageNavigationApi = (appType: any): string => {
  let pageNavApi = API.CMS_GET_PAGE_NAVIGATION;
  switch (appType) {
    case 'grandiaziende-homepage':
      pageNavApi = API.CMS_GET_PAGE_GRANDIAZIENDE_NAVIGATION;
      break;
    case 'business-homepage':
      pageNavApi = API.CMS_GET_PAGE_BUSINESS_NAVIGATION;
      break;
    default:
      break;
  }
  return pageNavApi;
};

const organizeCMSPages = (cmsPages: ICMSPage): IStaticPathPagesPaths[] => {
  const pagesToReturn: IStaticPathPagesPaths[] = [];
  const cmsPagesLength = Object.keys(cmsPages)?.length;
  if (cmsPagesLength <= 0) {
    return pagesToReturn;
  }
  Object.keys(cmsPages).forEach((cmsPageKey) => {
    const { pagesparams } = cmsPages[cmsPageKey];
    const { layout, pageType } = getPageInformation(cmsPages[cmsPageKey]);

    if (pagesparams.key) {
      const pageToAdd = {
        params: {
          isVBIPages: true, // NEW: always true, indicates that it is a page that uses business widgets
          ...(pageType && { pageType }), // NEW: values --> FIXED, VOCE, DATI, SMARTPHONE, TABLET etc...
          authDisabled: authDisabledLayout.includes(layout), // NEW: used in the authorization context to disable loggedIn and anonymous APIs
          key: pagesparams.key,
          layout,
          haveHeader: pagesparams.visibility?.haveHeader === 'true',
          isHeaderSmall: pagesparams.visibility?.isHeaderSmall === 'true',
          haveFooter: pagesparams.visibility?.haveFooter === 'true',
          isShow: pagesparams.visibility?.showPages === 'true',
          isDisabledInitialTrack: pagesparams.visibility?.isDisabledInitialTrack === 'true',
          isProtected: pagesparams.visibility?.isProtected === 'true',
          protectedPass: pagesparams.visibility?.protectedPass || '',
          path: pagesparams.urlBuilder?.map(
            (urlBuilder) => urlBuilder.page?.trim()?.toLowerCase() || ''
          ) || [''],
          elements: {
            ...cmsPages[cmsPageKey],
          },
        },
      };

      pagesToReturn.push(pageToAdd);
    }
  });
  return pagesToReturn;
};

const getPageInformation = (
  page: ICMSBusinessPageParams
): { layout: string; pageType?: ProductType } => {
  // check if is HUB page
  const hubProduct =
    Object.values(page).find(
      (widget) => widget.type === ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS
    ) || null;

  if (hubProduct) {
    return {
      layout: 'HUB',
      pageType: hubProduct?.elements?.[0]?.productType,
    };
  }

  // check if is PDP page
  const productWidget =
    Object.values(page).find(
      (widget) =>
        widget.type === ICMSWidgetType.PRODUCT_HERO_BANNER ||
        widget.type === ICMSWidgetType.DS_HERO_BANNER
    ) || null;

  if (productWidget) {
    return {
      layout: 'PDP',
      pageType: productWidget?.product?.productType,
    };
  }

  // check if is Secondary HUB page
  const secondaryHubWidget =
    Object.values(page).find((widget) => widget.type === ICMSWidgetType.HUB_DS) || null;

  if (secondaryHubWidget) {
    return {
      layout: 'SECONDARY_HUB',
      pageType: secondaryHubWidget?.productType,
    };
  }

  // check if is Appointment page
  const calendarWidget =
    Object.values(page).find((widget) => widget.type === ICMSWidgetType.SLOT_CALENDAR) || null;

  if (calendarWidget) {
    return {
      layout: 'APPOINTMENT',
    };
  }

  // check if is Sim Activation or Serial Confirmation page
  const simActivationWidget =
    Object.values(page).find(
      (widget) =>
        widget.type === ICMSWidgetType.SIM_ACTIVATION_OTP ||
        widget.type === ICMSWidgetType.SERIAL_CONFIRMATION_OTP
    ) || null;

  if (simActivationWidget) {
    return {
      layout: 'SIM_ACTIVATION',
    };
  }

  // default case
  return {
    layout: 'BLANK',
    ...getPageType(page?.pagetype as IPagesType),
  };
};

const getPageType = (types: IPagesType): { pageType?: ProductType } => {
  if (types?.isShoppingCart === 'true') return { pageType: 'FIXED' }; // add for shpping cart fwa page
  return {};
};

export async function safeParseJSON(response: any) {
  const body = await response.text();
  try {
    return JSON.parse(body);
  } catch (err) {
    console.error('Error:', err);
    console.error('Response body:', body);
    console.error('Response:', response);
    throw new Error(`Error with status: ${response?.status}, url: ${response?.url}`);
  }
}

const retrievePageNavigation = async (appType: any, env?: string) => {
  const ms = Date.now();
  const apiEnv = env || handleEnv('NEXT_PUBLIC_CMS');
  const apiPageNavigation = getPageNavigationApi(appType);
  const pageNavigationEp = await fetchRetry(
    `${apiEnv}/${isDevLocal && !env ? apiPageNavigation : `${apiPageNavigation}.json`}?t=${ms}`
  );
  await delay(200);
  const pageNavigationRes = await safeParseJSON(pageNavigationEp);
  return pageNavigationRes;
};

export { isDevLocal, organizeCMSPages, retrievePageNavigation };

