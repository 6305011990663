import { API } from '@vfit/shared/data-access';
import { handleEnv } from '@vfit/shared/data-access';
import { isDevLocal } from '../../cms/staticPathPages/staticPathPages.utils';

const TABLET_LIST = isDevLocal ? `${API.CMS_DT_GET_DEVICES}` : `${API.CMS_DT_GET_DEVICES}.json`;

/**
 * Retrieve Tablet visibility offers from AEM
 */
const getTabletSlug = async (): Promise<Array<{ params: { slug: string } }>> => {
  const allProductRes = await fetch(`${handleEnv('NEXT_PUBLIC_CMS')}/${TABLET_LIST}`);
  const allProductId = await allProductRes.json();
  const allSlugs: string[] = [];

  Object.values(allProductId).map((el) => {
    const widgetWithSlug = Object.values(el).find((w) => w?.type === 'DEVICE_HERO_BANNER');
    if (widgetWithSlug) allSlugs.push(widgetWithSlug?.product?.slug);
  });

  return allSlugs.map((fileName) => ({
    params: {
      slug: fileName.replace(/\.md$/, '')
    }
  }));
};

export { getTabletSlug };
