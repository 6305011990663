import { dxlClientWithOutJwt } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { Options } from "ky";
import { IKeepAliveResponse } from "./keepAlive.models";

/**
 * @description keepAlive
 * @returns  {Promise<IKeepAliveResponse>}
 */
export const keepAlive = async () =>
  await dxlClientWithOutJwt.get(
    API.KEEP_ALIVE,
    {
      prefixUrl: 'https://www.vodafone.it/',
      credentials: 'include',
    } as Options
  ) as Promise<IKeepAliveResponse>;
