import { useContext, useEffect } from 'react';
import {
  ProductType,
  formatDxlCostsDS,
  getEcommerceCatalogDSById,
} from '@vfit/business-data-access';
import {
  errorManager,
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  useHandleQueriesWithAuthSSO,
} from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { PAGES } from '@vfit/shared/data-access';

interface IElementsCatalog {
  isEditorialCard?: string;
  dxlId?: string;
  productType?: ProductType;
}

const useGetEcommerceCatalogDSById = (elements: IElementsCatalog[]) => {
  const { setIsLoading } = useContext(PageLoadingContext);
  const queriesDS: UseQueryConfig[] = [];
  elements.forEach((item) => {
    if (item.isEditorialCard === 'true') return;

    queriesDS.push({
      queryKey: [item.dxlId, 'ecommerceCatalogDSOffer'],
      queryFn: () =>
        getEcommerceCatalogDSById({
          businessSolutionId: [item?.dxlId || ''],
        }),
      options: {
        onSuccess: (data) => formatDxlCostsDS(data),
        onError: (error) => {
          const { status }: any = error;
          const statusNumber = Number(status);
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorCode: statusNumber,
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    });
  });

  const queriesResults = useHandleQueriesWithAuthSSO(queriesDS);

  useEffect(() => {
    if (Object.values(queriesResults).some((item: any) => item.status !== 'success')) return;
    if (
      elements.length === Object.values(queriesResults).length &&
      Object.values(queriesResults).every((item: any) => item.status === 'success') &&
      Object.values(queriesResults).every(
        (item: any) => item.data?.businessSolutionInfo.length === 0
      )
    ) {
      errorManager.handleError(ErrorSeverity.HIGH, {
        errorAction: ErrorAction.PAGE,
        errorPage: PAGES.OFFER_ERROR,
      });
    } else {
      setIsLoading(false);
    }
  }, [queriesResults]);

  const catalog = Object.values(queriesResults)
    .map((query: any) => query.data)
    .filter(Boolean);

  return {
    data: catalog,
  };
};

export { useGetEcommerceCatalogDSById };

