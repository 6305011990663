import { dxlCloudClientWithJwt } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import {
  IVerifyConfirmationOtpTokenRequest,
  IVerifyConfirmationOtpTokenResponse,
} from './verifyConfirmationOtpToken.models';

export const postVerifyConfirmationOtpToken = (payload: IVerifyConfirmationOtpTokenRequest) =>
  dxlCloudClientWithJwt.post(
    API.AWS_VERIFY_CONFIRMATION_OTP_TOKEN,
    payload
  ) as Promise<IVerifyConfirmationOtpTokenResponse>;

