import { useRouter } from 'next/router';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import slugify from "slugify";

interface INation {
  nation: INationObj;
  pageType: object;
  appType: string;
  setNation: React.Dispatch<React.SetStateAction<object>>;
}
export interface INationObj {
  name: string;
  slug: string;
  prefix: string;
  isEU: boolean | undefined;
  flag: string;
}

const initialState: INation = {
  nation: {
    name: '',
    slug: '',
    prefix: '',
    isEU: false,
    flag: '',
  },
  pageType: {},
  appType: '',
  setNation: () => {},
};

export interface IPrimaryList {
  continente: string;
  slug: string;
  nome: string;
  isEU?: boolean;
}

export type INations = Array<IPrimaryList>;

export const NationContext = createContext(initialState);

export const NationContextProvider = ({
  pageType,
  children,
  appType,
}: {
  pageType: object;
  children: ReactNode;
  appType?: string;
}) => {
  const { push, asPath, query, isReady } = useRouter();
  const [nation, setNation] = useState<INationObj>();
  const country = slugify(String(query?.["country"]), {
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  });

  const queryClient = useQueryClient();

  const nations = queryClient.getQueryData('getNations') as [];

  useEffect(() => {
    if (!isReady) return;
    if (pageType['isDetailCountry'] === 'true') {
      const getCountry: INations = nations.filter((c: IPrimaryList) => c.slug === country);

      if (getCountry.length > 0) {
        setNation({
          name: getCountry[0]['nome'],
          slug: getCountry[0]['slug'],
          prefix: getCountry[0]['prefix'],
          isEU: getCountry[0]['isEU'],
          flag: getCountry[0]['flag'],
        });
      } else {
        push(asPath.slice(0, asPath.lastIndexOf('/')));
      }
    }
  }, [isReady, country]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { nation, pageType, setNation, appType };

  return <NationContext.Provider value={value}>{children}</NationContext.Provider>;
};
