import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IEcommerceCatalogResponse, EcommerceCatalog } from './getEcommerceCatalog.models';

export const postEcommerceCatalogDigitalSolutions = (payload: EcommerceCatalog) =>
  dxlClient.post(API.DXL_GET_ECOMMERCE_CATALOG, payload) as Promise<IEcommerceCatalogResponse>;

export const getEcommerceCatalogDigitalSolutions = (payload: EcommerceCatalog) =>
  dxlClient.get(API.DXL_GET_ECOMMERCE_CATALOG, {
    searchParams: { ...payload },
  }) as Promise<IEcommerceCatalogResponse>;

