import {
  ErrorAction,
  ErrorSeverity,
  errorManager,
  useHandleQueryWithAuthSSO,
} from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { useState } from 'react';
import { getEcommerceCatalogDevices } from '../../api/getEcommerceCatalog/getEcommerceCatalogDevices';
import { IEcommerceCatalogResponse } from '../../api';

interface IElementsCatalog {
  deviceId: string;
}

const useGetEcommerceCatalogDeviceInfo = ({ deviceId }: IElementsCatalog) => {
  const [isLoadingDeviceInfo, setIsLoadingDeviceInfo] = useState(true);
  const getEcommerceCatalogDeviceInfo = {
    queryKey: [deviceId, 'ecommerceCatalogDeviceInfo'],
    queryFn: () =>
      getEcommerceCatalogDevices({
        deviceId: [deviceId],
      }),
    options: {
      onError: (error) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
      onSettled: () => {
        setIsLoadingDeviceInfo(false);
      },
    },
  } as UseQueryConfig;

  const { data } = useHandleQueryWithAuthSSO(getEcommerceCatalogDeviceInfo);

  return { data: data as IEcommerceCatalogResponse, isLoadingDeviceInfo };
};

export { useGetEcommerceCatalogDeviceInfo };

