import React, {createContext, PropsWithChildren, useState} from 'react';

interface ILoading {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: ILoading = {
  isLoading: true,
  setIsLoading: () => {},
}

export const PageLoadingContext = createContext(initialState);

export const PageLoadingContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const value = { isLoading, setIsLoading }

  return (
    <>
      <PageLoadingContext.Provider value={value}>{children}</PageLoadingContext.Provider>
    </>
  );
};