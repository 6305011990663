import { IPlaceholder, PlaceholdersID, PlaceholdersMap } from '@vfit/business-data-access';
import { IProductInfo, invalidPlaceholderNumber, IResource, DXL } from '../api';

const findValue = (data: IProductInfo | any, key: string) => {
  const arr = key.split('.');
  let newData = data as any;

  arr.map((e) => {
    if (typeof newData?.[e] !== undefined) newData = newData[e];
    else newData = '';
  });

  return newData;
};

export const getPriceDescription = (productCatalog: IProductInfo, placeholders: IPlaceholder[]) => {
  const data = productCatalog?.addOn?.find((e) => e.productType === 'A');
  const newData = { ...productCatalog, addon: data };
  if (data?.costs?.renewalCost! > 0 && data?.costs?.discountedRenewalCost === 0)
    // this condition must always be the first to check
    return getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_RECURRING_COST);
  if (data?.costs?.renewalCost! > 0 && data?.costs?.discountedRenewalCost! > 0)
    return getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_RECURRING_COST);
  if (data?.costs?.renewalCost! > 0 && !data?.costs?.discountedRenewalCost)
    return getLabel(newData, placeholders, PlaceholdersID.ACT_RECURRING_COST_WITH_FEE);
  return '';
};

export const getUnaTantumPriceDescription = (
  productCatalog: IProductInfo,
  placeholders: IPlaceholder[]
) => {
  const data = productCatalog?.addOn?.find((e) => e.productType === 'A');
  const newData = { ...productCatalog, addon: data };

  if (data?.costs?.activationCost! > 0 && data?.costs?.discountedActivationCost === 0)
    // this condition must always be the first to check
    return getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_COST);
  if (data?.costs?.activationCost! > 0 && data?.costs?.discountedActivationCost! > 0)
    return getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_COST);
  if (data?.costs?.activationCost! > 0 && !data?.costs?.discountedActivationCost)
    return getLabel(newData, placeholders, PlaceholdersID.ACT_COST_WITH_FEE);

  return '';
};

export const getResponsivePrice = (productCatalog: IProductInfo, placeholders: IPlaceholder[]) => {
  if (productCatalog?.costs?.discountedRenewalCost) {
    getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_DISCOUNTED_RESPONSIVE);
  } else {
    getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_WITH_FEE_RESPONSIVE);
  }
  const price =
    productCatalog?.costs?.discountedRenewalCost! || productCatalog?.costs?.renewalCost!;
  return getLabel(
    { costs: { renewalCost: price } },
    placeholders,
    PlaceholdersID.CORE_OFFER_COST_WITH_FEE_RESPONSIVE
  );
};

export const getPriceDescriptionShoppingCart = (
  productCatalog: IProductInfo,
  placeholders: IPlaceholder[]
) => {
  const addon = productCatalog?.addOn?.find((e) => e.productType === 'A');
  const newData = { ...productCatalog, addon };

  if (addon?.costs?.renewalCost! > 0 && addon?.costs?.discountedRenewalCost === 0) {
    return productCatalog?.costs?.discountedRenewalCost
      ? getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_AND_RECURRING_DISCOUNTED_COST_MAP)
      : getLabel(newData, placeholders, PlaceholdersID.FREE_ACT_AND_RECURRING_COST_MAP);
  }

  if (addon?.costs?.renewalCost! > 0 && addon?.costs?.discountedRenewalCost! > 0) {
    return productCatalog?.costs?.discountedRenewalCost
      ? getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_RECURRING_DISCOUNTED_COST_MAP)
      : getLabel(newData, placeholders, PlaceholdersID.DISCOUNTED_ACT_RECURRING_COST_MAP);
  }

  if (addon?.costs?.renewalCost! > 0 && !addon?.costs?.discountedRenewalCost) {
    return productCatalog?.costs?.discountedRenewalCost
      ? getLabel(newData, placeholders, PlaceholdersID.ACT_AND_RECURRING_DISCOUNTED_COST_MAP)
      : getLabel(newData, placeholders, PlaceholdersID.ACT_AND_RECURRING_COST_MAP);
  }
  return '';
};

export const getPriceDescriptionAddons = (addon: IProductInfo, placeholders: IPlaceholder[]) => {
  if (addon?.costs?.renewalCost! > 0 && !addon?.costs?.discountedRenewalCost) {
    return getLabel(addon, placeholders, PlaceholdersID.CORE_OFFER_COST_FOR_ADDON);
  }

  // if (addon?.costs?.renewalCost! > 0 && addon?.costs?.discountedRenewalCost! > 0){
  //   getLabel(addon,placeholders,PlaceholdersID.DISCOUNTED_CORE_OFFER_FOR_ADDON)
  // }
  return '';
};

export const getPrice = (productCatalog: IProductInfo, placeholders: IPlaceholder[]) => {
  if (
    productCatalog?.costs?.renewalCost! > 0 &&
    (!productCatalog?.costs?.discountedRenewalCost ||
      productCatalog.costs.discountedRenewalCost === 0)
  )
    return getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_WITH_FEE);
  if (productCatalog?.costs?.renewalCost! > 0 && productCatalog?.costs?.discountedRenewalCost! > 0)
    return getLabel(productCatalog, placeholders, PlaceholdersID.CORE_OFFER_COST_DISCOUNTED);
  return '';
};

export const getOfferName = (productCatalog: IProductInfo, placeholders: IPlaceholder[]) =>
  getLabel(productCatalog, placeholders, PlaceholdersID.OFFER_NAME);

export const getLabel = (
  productCatalog: IProductInfo | any,
  placeholders: IPlaceholder[],
  key: string
): string => {
  let label = placeholders.find((e) => e.id === key) || { value: '' };
  const reg = new RegExp(/([$])\w+/g);

  label.value.match(reg)?.map((e) => {
    let labelValue = findValue(productCatalog, PlaceholdersMap[e.substring(1)]);
    if (typeof labelValue === 'number' && isValidLabelNumber(e)) {
      labelValue = formatPrice(labelValue);
    }
    label = {
      ...label,
      value: label.value.replace(e, labelValue)
    };
  });
  return label.value;
};

export const formatPrice = (val: number) => val?.toFixed(2).replace('.', ',');

// devices
export const getPriceDescriptionDeviceCard = (price: string, description: string) => {
  const value = formatPrice(Number(price));
  let label = { value: '' };
  const reg = new RegExp(/([$])\w+/g);
  description.match(reg)?.map((e) => {
    label = {
      ...label,
      value: description.replace(e, value)
    };
  });
  return label.value;
};

export const getPriceDevice = (mapped: IResource, placeholders: IPlaceholder[]) => {
  const data = mapped.costs;
  if (data?.activationCost! === 0 && !data?.discountedActivationCost)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_FREE);

  if (data?.activationCost! > 0 && data?.discountedActivationCost! >= 0)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_DISCOUNTED);

  if (data?.activationCost! > 0 && !data?.discountedActivationCost)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_WITH_FEE);

  return '';
};

export const getPriceDeviceSpecificOffer = (mapped: IResource, placeholders: IPlaceholder[]) => {
  const data = mapped?.costs;

  if (data?.activationCost! === 0 && !data?.discountedActivationCost)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_FREE_CUSTOMIZATION);

  if (data?.activationCost! > 0 && data?.discountedActivationCost! >= 0)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_DISCOUNTED_CUSTOMIZATION);
  if (data?.activationCost! > 0 && !data?.discountedActivationCost)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_ACT_COST_WITH_FEE_CUSTOMIZATION);
  return '';
};

export const sumPriceDeviceAndOffer = (mapped: IResource) => {
  const offer = mapped?.product?.[0];

  const costDevice = mapped?.costs?.discountedRenewalCost || mapped?.costs?.renewalCost || 0;
  const costOffer = offer?.costs?.discountedRenewalCost || offer?.costs?.renewalCost || 0;

  return costDevice + costOffer;
};

export const getPriceOfferAndDevice = (
  mapped: IResource,
  placeholders: IPlaceholder[],
  key: PlaceholdersID
) => {
  const sum = sumPriceDeviceAndOffer(mapped);

  return getLabel({ costs: { renewalCost: sum } }, placeholders, key);
};

export const getPriceOfferDevice = (mapped: IResource, placeholders: IPlaceholder[]) => {
  const data = mapped.costs;
  if (data?.renewalCost! > 0 && data?.discountedRenewalCost! >= 0)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_COST_DISCOUNTED);
  if (data?.renewalCost! >= 0 && !data?.discountedRenewalCost)
    return getLabel(mapped, placeholders, PlaceholdersID.DEVICE_COST_WITH_FEE);

  return '';
};

// DS IOT

export const getDSIOTPrice = (cost: DXL.ICost, placeholders: IPlaceholder[]) => {
  const costObj = {
    costs: {
      ...cost
    }
  };

  if (cost?.renewalCost > 0 && cost.discountedRenewalCost === 0)
    return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_FREE);
  if (cost?.renewalCost > 0 && !cost?.discountedRenewalCost)
    return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_WITH_FEE);
  if (cost?.renewalCost > 0 && cost?.discountedRenewalCost > 0)
    return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_DISCOUNTED_DS);

  return getDSIOTUnaTantum(cost, placeholders);
};

export const getDSIOTActivationPrice = (cost: DXL.ICost, placeholders: IPlaceholder[]) => {
  const costObj = {
    costs: {
      ...cost
    }
  };

  if (cost?.renewalCost || cost?.discountedActivationCost) {
    if (cost?.activationCost > 0 && cost?.discountedActivationCost === 0)
      return getLabel(costObj, placeholders, PlaceholdersID.DISCOUNTED_ACT_COST_FREE);
    if (cost?.activationCost > 0 && !cost?.discountedActivationCost)
      return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_ACT_COST_WITH_FEE);
    if (cost?.activationCost > 0 && cost?.discountedActivationCost > 0)
      return getLabel(costObj, placeholders, PlaceholdersID.DISCOUNTED_ACT_COST_WITH_FEE);
    if (cost?.activationCost === 0 && !cost?.discountedActivationCost)
      return getLabel(costObj, placeholders, PlaceholdersID.TOTAl_ACT_COST_FREE);
    return '';
  }
};

export const getDSIOTResponsivePrice = (cost: DXL.ICost, placeholders: IPlaceholder[]) => {
  const price = cost?.discountedRenewalCost || cost?.renewalCost || cost?.activationCost;

  return getLabel(
    { costs: { renewalCost: price } },
    placeholders,
    PlaceholdersID.TOTAL_COST_WITH_FEE
  );
};

export const getDSIOTUnaTantum = (cost: DXL.ICost, placeholders: IPlaceholder[]) => {
  const costObj = {
    costs: {
      ...cost
    }
  };

  if (cost?.renewalCost === 0 && cost?.activationCost > 0 && !cost?.discountedActivationCost) {
    return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_UNA_TANTUM);
  }
  if (cost?.renewalCost === 0 && cost?.activationCost > 0 && cost?.discountedActivationCost > 0) {
    return getLabel(costObj, placeholders, PlaceholdersID.TOTAL_COST_UNA_TANTUM_DISCOUNTED);
  }
  return '';
};

const isValidLabelNumber = (label: string) => !invalidPlaceholderNumber.find((e) => e === label);

export const getDeviceTotalRenewalCost = (mapped: IResource, placeholders: IPlaceholder[]) => {
  const data = mapped.costs;
  const costDevice = (data?.discountedRenewalCost! >= 0 && data?.renewalCost! > 0 ? data?.discountedRenewalCost : data?.renewalCost) || 0;
  const renewalNumber = typeof data?.renewalNumber === 'number' && data?.renewalNumber > 0 ? data?.renewalNumber : 1;

  return getLabel({ costs: { renewalCost: costDevice * renewalNumber } }, placeholders, PlaceholdersID.TOTAL_RENEWAL_COST_DEVICE);
};

export const getDeviceTotalCost = (mapped: IResource, placeholders: IPlaceholder[]) => {
  const data = mapped.costs;
  const costDevice = (data?.discountedRenewalCost! >= 0 && data?.renewalCost! > 0 ? data?.discountedRenewalCost : data?.renewalCost) || 0;
  const renewalNumber = typeof data?.renewalNumber === 'number' && data?.renewalNumber > 0 ? data?.renewalNumber : 1;
  const activationCost = (data?.discountedActivationCost! >= 0 && data?.activationCost! > 0 ? data?.discountedActivationCost : data?.activationCost) || 0;

  return getLabel({ costs: { renewalCost: costDevice * renewalNumber + activationCost } }, placeholders, PlaceholdersID.TOTAL_DEVICE_COST);
};
