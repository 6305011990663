import { API } from '@vfit/shared/data-access';
import { handleEnv } from '@vfit/shared/data-access';
import { ICMSWidgetType } from '../../cms';
import { isDevLocal } from '../../cms/staticPathPages/staticPathPages.utils';

const SMARTPHONE_LIST = isDevLocal ? `${API.CMS_DS_GET_DEVICES}` : `${API.CMS_DS_GET_DEVICES}.json`;

/**
 * Retrieve Smartphone line visibility offers from AEM
 */
const getSmartphoneSlug = async (): Promise<Array<{ params: { slug: string } }>> => {
  const allProductRes = await fetch(`${handleEnv('NEXT_PUBLIC_CMS')}/${SMARTPHONE_LIST}`);
  const allProductId = await allProductRes.json();
  const allSlugs: string[] = [];

  Object.values(allProductId).map((el: any) => {
    const widgetWithSlug: any = Object.values(el).find((w: any) => w?.type === ICMSWidgetType.DEVICE_HERO_BANNER);
    if (widgetWithSlug) allSlugs.push(widgetWithSlug?.product?.slug);
  });

  return allSlugs.map((fileName) => ({
    params: {
      slug: fileName.replace(/\.md$/, '')
    }
  }));
};

export { getSmartphoneSlug };
