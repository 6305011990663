import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IEcommerceCatalogRequest, IEcommerceCatalogResponse } from './getEcommerceCatalog.models';

export const getEcommerceCatalogWithAddress = (payload: IEcommerceCatalogRequest) =>
  dxlClient.post(
    API.DXL_GET_ECOMMERCE_CATALOG, // TODO: change this to the correct endpoint
    payload
  ) as Promise<IEcommerceCatalogResponse>;

