import { dxlCloudClient } from '../../../index'
import { API } from '@vfit/shared/data-access';
import {
  IAvailableTimeSlotsRequest,
  IAvailableTimeSlotsResponse
} from './availableTimeSlots.models';

export const awsAvailableTimeSlots = (payload: IAvailableTimeSlotsRequest) =>
  dxlCloudClient.post(
    API.AWS_AVAILABLE_TIME_SLOTS,
    payload
  ) as Promise<IAvailableTimeSlotsResponse>;
