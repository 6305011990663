export * from './coverage-tool';
export * from './dxl-anonymous-login';
export * from './getEcommerceCatalog';
export * from './static-path-offers';
export * from './common-placerHolder';
export * from './geographic-address-management';
export * from './dxl-cb-sso-login';
export * from './dxl-cookie-login';
export * from './dxl-get-profile';
export * from './is-logged';
export * from './keep-alive';
export * from './dxl-post-create-shopping-cart';
export * from './getListLandingPage';
export * from './availableTimeSlots';
export * from './schedule';
export * from './sendOtp';
export * from './verifyRetrieveData';
export * from './sendConfirmationOtp';
export * from './verifyConfirmationOtpToken';
export * from './endSerialConfirmation';

