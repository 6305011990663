import {
  ErrorAction,
  errorManager,
  ErrorSeverity,
  PageLoadingContext,
  useHandleQueryWithAuthSSO,
} from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { useContext } from 'react';
import { DXL, IEcommerceCatalogResponse, ProductType } from '../../api';
import { getEcommerceCatalogDigitalSolutions } from '../../api/getEcommerceCatalog/getEcommerceCatalogDigitalSolutions';

interface IElementsCatalog {
  limit?: string;
  category?: ProductType;
}

const useGetEcommerceCatalogDigitalSolutions = ({ limit, category }: IElementsCatalog) => {
  const { setIsLoading } = useContext(PageLoadingContext);

  const sortByPositionDS = (dataDS: IEcommerceCatalogResponse) =>
    dataDS.businessSolutionInfo?.sort((a, b) => {
      const posA = a.editionInfo?.[0].characteristic?.find((c) => c.name === 'eCommercePosition')
        ?.value?.[0] as DXL.ICharacteristicValue;
      const posB = b.editionInfo?.[0].characteristic?.find((c) => c.name === 'eCommercePosition')
        ?.value?.[0] as DXL.ICharacteristicValue;
      if (posA?.value && posB?.value) return Number(posA?.value) > Number(posB?.value) ? 1 : -1;
      return posA?.value ? -1 : 1;
    });

  const getAllDigitalSolutions = {
    queryKey: 'ecommerceCatalogDigitalSolutions',
    queryFn: () =>
      getEcommerceCatalogDigitalSolutions({
        limit,
        offset: limit && '0',
        productType: category && [category],
      }),
    options: {
      onSuccess: (data) => sortByPositionDS(data),
      onError: (error) => {
        const { status }: any = error;
        const statusNumber = Number(status);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: statusNumber,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  } as UseQueryConfig;

  const { data } = useHandleQueryWithAuthSSO(getAllDigitalSolutions);

  return { data };
};

export { useGetEcommerceCatalogDigitalSolutions };

