import {
  IDescriptionVolaUrl,
  IEcommerceCatalogResponse,
  IResource,
  ITaggingCMS,
  ProductType,
  useGetProfile,
} from '@vfit/business-data-access';
import { IDatalayer, tracking, useTrackingProvider } from '@vfit/shared/data-access';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { PageLoadingContext } from './pageLoadingContext';
import {
  getDeviceTrackInformation,
  getProductTrackInformation,
  getDeviceInformation,
  getCoreOfferInformation,
  getVisitorTrackInformation,
  getDigitalSolutionInformation,
  getDigitalSolutionTrackInformation,
} from '../utils/trackingUtils';

interface ITrackingProps {
  hubProduct?: any;
  selectedProduct?: any;
  tagging: ITaggingCMS;
  children: ReactNode;
  layer: string;
  type?: ProductType;
}

interface ITrackingPage {
  link: (datalayer: IDatalayer, productId?: string) => any;
  linkError: (datalayer: IDatalayer) => any;
  linkCommon: (datalayer: IDatalayer) => any;
  view: (datalayer: IDatalayer, productId?: string) => any;
  setCurrentDataLayer: React.Dispatch<React.SetStateAction<any>>;
  currentDataLayer: {
    hubDataLayer: IDatalayer;
    coverageToolDataLayer: IDatalayer;
    shoppingCartLayer: IDatalayer;
    pdpDataLayer: IDatalayer;
    blankDataLayer: IDatalayer;
    appointmentDataLayer: IDatalayer;
    simActivationDataLayer: IDatalayer;
  };
  selectedResource?: IResource;
  setSelectedResource: React.Dispatch<React.SetStateAction<any>>;
  pageType?: ProductType;
  descriptionVolaUrl?: IDescriptionVolaUrl;
  tagging?: ITaggingCMS;
}

const initialState: ITrackingPage = {
  link: () => {},
  linkError: () => {},
  linkCommon: () => {},
  view: () => {},
  setCurrentDataLayer: () => {},
  currentDataLayer: {
    hubDataLayer: {
      event_name: [],
    },
    coverageToolDataLayer: {
      event_name: [],
    },
    shoppingCartLayer: {
      event_name: [],
    },
    pdpDataLayer: {
      event_name: [],
    },
    blankDataLayer: {
      event_name: [],
    },
    appointmentDataLayer: {
      event_name: [],
    },
    simActivationDataLayer: {
      event_name: [],
    },
  },
  selectedResource: undefined,
  setSelectedResource: () => {},
  pageType: undefined,
  descriptionVolaUrl: undefined,
  tagging: undefined,
};

export const TrackingPageContext = createContext(initialState);

export const TrackingPageContextProvider = ({
  hubProduct,
  selectedProduct,
  tagging,
  children,
  layer,
  type = undefined,
}: ITrackingProps) => {
  const { isLoading } = useContext(PageLoadingContext);
  const { isReady: isReadyTagging } = useTrackingProvider();

  const queryClient = useQueryClient();
  const visitortTrackingInformation = getVisitorTrackInformation(queryClient);
  useGetProfile();
  const [currentDataLayer, setCurrentDataLayer] = useState(initialState.currentDataLayer);
  const [selectedResource, setSelectedResource] = useState(initialState.selectedResource);
  const [descriptionVolaUrl, setDescriptionVolaUrl] = useState(initialState.descriptionVolaUrl);

  const link = (datalayer: IDatalayer, productId?: string) => {
    const productTrackingInformation = getTrackingProductFunction(productId);
    return tracking(
      {
        ...datalayer,
        event_name: ['ui_interaction'],
        event_category: ['users'],
        event_action: 'click',
        ...(productTrackingInformation || {}),
        ...visitortTrackingInformation,
      },
      'link'
    );
  };

  const linkCommon = (datalayer: IDatalayer, productId?: string) => {
    const productTrackingInformation = getTrackingProductFunction(productId);
    return tracking(
      {
        ...datalayer,
        ...(productTrackingInformation || {}),
        ...visitortTrackingInformation,
      },
      'link'
    );
  };

  const linkError = (datalayer: IDatalayer) => {
    return tracking(
      {
        ...datalayer,
        event_name: ['form_error'],
        event_category: ['error'],
      },
      'link'
    );
  };

  const view = (datalayer: IDatalayer, productId?: string) => {
    const productTrackingInformation = getTrackingProductFunction(productId);

    return tracking(
      {
        ...datalayer,
        ...(productTrackingInformation || {}),
        ...visitortTrackingInformation,
      },
      'view'
    );
  };

  const getTrackingProductFunction = (productId?: string) => {
    if (type === 'SMARTPHONE' || type === 'TABLET') {
      const deviceCatalog = queryClient.getQueryData(
        'ecommerceCatalogDevices'
      ) as IEcommerceCatalogResponse;
      const product = hubProduct
        ? deviceCatalog?.deviceInfo?.find((el) => el?.deviceId === productId)
        : selectedProduct;
      return getDeviceTrackInformation(queryClient, product, selectedResource);
    }

    if (type === 'INNOVATIVE') {
      const digitalSolutionCatalog = queryClient.getQueryData(
        'ecommerceCatalogDigitalSolutions'
      ) as IEcommerceCatalogResponse;
      const product = hubProduct
        ? digitalSolutionCatalog?.businessSolutionInfo?.find(
            (el) => el?.businessSolutionId === productId
          )
        : selectedProduct;
      return product && getDigitalSolutionTrackInformation(queryClient, product, productId);
    }

    const product = hubProduct
      ? hubProduct?.elements.find((el: any) => el?.dxlId === productId)
      : selectedProduct;
    return product && getProductTrackInformation(queryClient, product);
  };

  useEffect(() => {
    // added exception to call tracking manually for simActivationDataLayer
    if (
      isReadyTagging &&
      (!isLoading || layer === 'blankDataLayer') &&
      layer !== 'simActivationDataLayer'
    ) {
      let productsInformation = [];

      if (type === 'SMARTPHONE' || type === 'TABLET')
        productsInformation = getDeviceInformation(queryClient, hubProduct, selectedProduct);
      else if (type === 'INNOVATIVE')
        productsInformation = getDigitalSolutionInformation(
          queryClient,
          hubProduct,
          selectedProduct
        );
      else
        productsInformation = getCoreOfferInformation(
          queryClient,
          hubProduct?.elements,
          selectedProduct
        );

      const viewDataLayer = tracking(
        {
          event_name: [tagging?.event!],
          page_section: tagging?.pageSection,
          page_subsection: tagging?.pageSubsection,
          page_type: tagging?.pageType,
          journey_type: tagging?.journeyType || 'task',
          event_category: [tagging?.eventCategory!],
          ...productsInformation,
          ...visitortTrackingInformation,
        },
        'view'
      );

      setDescriptionVolaUrl(tagging?.descriptionVolaUrl);
      setCurrentDataLayer({ ...currentDataLayer, [layer]: viewDataLayer });
    }
  }, [isLoading, isReadyTagging]);

  return (
    <TrackingPageContext.Provider
      value={{
        link,
        linkError,
        linkCommon,
        view,
        currentDataLayer,
        setCurrentDataLayer,
        selectedResource,
        setSelectedResource,
        pageType: type,
        descriptionVolaUrl,
        tagging,
      }}
    >
      {children}
    </TrackingPageContext.Provider>
  );
};

