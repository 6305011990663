import { useContext, useEffect, useState } from 'react';
import {
  ProductType,
  getEcommerceCatalog,
  getProductVersion,
  formatDxlCosts,
} from '@vfit/business-data-access';
import {
  errorManager,
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  useHandleQueriesWithAuthSSO,
} from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { PAGES } from '@vfit/shared/data-access';

interface IElementsCatalog {
  isEditorialCard?: string;
  dxlId?: string;
  productType?: ProductType;
  version?: string;
  landingprive?: {
    isPrive?: string;
  };
}

const useGetProductEcommerceCatalog = (elements: IElementsCatalog[], enabled = true) => {
  const { setIsLoading } = useContext(PageLoadingContext);
  const [isLoadingEcomm, setIsLoadingEcomm] = useState(true);
  const queries: UseQueryConfig[] = [];
  elements.forEach((item) => {
    if (item.isEditorialCard === 'true') return;
    const version = getProductVersion(item?.dxlId, item?.version, item?.landingprive?.isPrive);

    queries.push({
      queryKey: [item.dxlId, 'ecommerceCatalogCoreOffer'],
      queryFn: () =>
        getEcommerceCatalog({
          productId: item.dxlId,
          productType: item.productType,
          ...(version && { productVersion: version }),
        }),
      options: {
        onSuccess: (data) => formatDxlCosts(data),
        onError: (error) => {
          const { status }: any = error;
          const statusNumber = Number(status);
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorCode: statusNumber,
          });
        },
        enabled,
      },
    });
  });

  const queriesResults = useHandleQueriesWithAuthSSO(queries);

  useEffect(() => {
    if (!isLoadingEcomm) setIsLoading(false);
  }, [isLoadingEcomm]);

  useEffect(() => {
    if (Object.values(queriesResults).some((item: any) => item.status !== 'success')) return;
    if (
      elements.length === Object.values(queriesResults).length &&
      Object.values(queriesResults).every((item: any) => item.status === 'success') &&
      Object.values(queriesResults).every((item: any) => item.data?.productInfo.length === 0)
    ) {
      errorManager.handleError(ErrorSeverity.HIGH, {
        errorAction: ErrorAction.PAGE,
        errorPage: PAGES.OFFER_ERROR,
      });
    } else {
      setIsLoadingEcomm(false);
    }
  }, [queriesResults]);

  const catalog = Object.values(queriesResults)
    .map((query: any) => query.data?.productInfo[0]!)
    .filter(Boolean);

  return {
    catalog,
  };
};

export { useGetProductEcommerceCatalog };

