import { dxlCloudClientWithJwt } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import {
  IEndSerialConfirmationRequest,
  IEndSerialConfirmationResponse,
} from './endSerialConfirmation.models';

export const postEndSerialConfirmation = (payload: IEndSerialConfirmationRequest) =>
  dxlCloudClientWithJwt.post(
    API.AWS_END_SERIAL_CONFIRMATION,
    payload
  ) as Promise<IEndSerialConfirmationResponse>;

