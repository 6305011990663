import { DXL, IEcommerceCatalogResponse, IProductInfo } from '../api';

export const formatDxlCosts = (data: IEcommerceCatalogResponse) => {
  let newData = data;
  if(newData?.productInfo?.[0]?.costs && Array.isArray(newData?.productInfo?.[0]?.costs)) {
    newData.productInfo[0].costs = newData.productInfo?.[0]?.costs[0];
  }
  newData.productInfo?.[0]?.addOn?.map((a: IProductInfo, i: number) => {
    if(Array.isArray(a?.costs)) {
      if(newData.productInfo?.[0].addOn?.[i]) newData.productInfo[0].addOn[i].costs = a?.costs?.[0];
    }
  });    
  return newData;
};

export const formatDxlCostsDS = (data: IEcommerceCatalogResponse) => {
  const newData = data;

  // business solution cost
  if (
    newData.businessSolutionInfo?.[0]?.costs &&
    Array.isArray(newData.businessSolutionInfo?.[0]?.costs)
  ) {
    newData.businessSolutionInfo[0].costs = newData.businessSolutionInfo?.[0]?.costs?.[0];
  }

  // edition cost
  newData.businessSolutionInfo?.[0]?.editionInfo?.forEach((item, i) => {
    if (newData.businessSolutionInfo?.[0].editionInfo && item.costs && Array.isArray(item.costs)) {
      newData.businessSolutionInfo[0].editionInfo[i].costs = item.costs?.[0];
    }
  });

  // device cost
  if (
    newData.businessSolutionInfo?.[0]?.editionInfo?.[0]?.device?.[0]?.costs &&
    Array.isArray(newData.businessSolutionInfo?.[0]?.editionInfo?.[0]?.device?.[0]?.costs)
  ) {
    newData.businessSolutionInfo[0].editionInfo[0].device[0].costs =
      newData.businessSolutionInfo?.[0]?.editionInfo?.[0]?.device?.[0]?.costs?.[0];
  }

  return newData;
};

export const getCharacteristicValues = (characteristic: DXL.ICharacteristic[], name: string) => {
  const values = characteristic?.find((ch) => ch.name === name)
    ?.value as DXL.ICharacteristicValue[];

  return values?.[0]?.value || '';
};
