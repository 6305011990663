import {
  ErrorAction,
  errorManager,
  ErrorSeverity,
  PageLoadingContext,
} from '@vfit/business-data-access';
import { PAGES } from '@vfit/shared/data-access';
import { UseQueryResult } from 'react-query';
import { handleUseQuery, INextError, UseQueryConfig } from '@vfit/shared/data-access';
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { HTTPError } from 'ky';
import { awsAvailableTimeSlots, IAvailableTimeSlotsResponse } from '../../api/availableTimeSlots';

const useAvailableTimeSlots = (token: string, isOperator: boolean) => {
  const { setIsLoading } = useContext(PageLoadingContext);
  const [reload, setReload] = useState(false);

  const getAllSlotTime = {
    queryKey: 'awsAvailableTimeSlots',
    queryFn: () =>
      awsAvailableTimeSlots({
        token,
      }),
    options: {
      onSuccess: () => {
        setIsLoading(false);
        setReload(false);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      onError: async (error: HTTPError) => {
        const resp = await error.response.json().catch(() => ({
          code: 'generic-error',
        }));
        const { errorPage, message } = getErrorPage(resp?.['code'], isOperator);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorPage,
          message,
        });
      },
      refetchInterval: 600000,
    },
  } as UseQueryConfig;

  const { data, isFetching } = handleUseQuery(getAllSlotTime) as UseQueryResult<
    IAvailableTimeSlotsResponse,
    INextError
  >;

  const timeoutRef: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  useEffect(() => {
    if (data?.expiredTimestamp) {
      clearTimeout(timeoutRef.current);
      const timeUntilExpiration = new Date(Number(data?.expiredTimestamp)).getTime() - Date.now();
      timeoutRef.current = setTimeout(() => {
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorPage: isOperator ? PAGES.TIME_EXPIRED_OPERATOR_ERROR : PAGES.TIME_EXPIRED_ERROR,
          message: 'time-expired-error',
        });
      }, timeUntilExpiration);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [data?.expiredTimestamp]);

  useEffect(() => {
    if (isFetching) {
      setReload(true);
    }
  }, [isFetching]);

  return { data, reload };
};

const getErrorPage = (error: string, isOperator?: boolean) => {
  const code = error?.split(':')?.pop();

  switch (code) {
    case 'slot-not-available':
      return {
        errorPage: isOperator ? PAGES.NO_AVAILABILITY_OPERATOR_ERROR : PAGES.NO_AVAILABILITY_ERROR,
        message: 'no-availability-error',
      };
    case 'time-expired':
      return {
        errorPage: isOperator ? PAGES.TIME_EXPIRED_OPERATOR_ERROR : PAGES.TIME_EXPIRED_ERROR,
        message: 'time-expired-error',
      };
    default:
      return {
        errorPage: PAGES.GENERIC_ERROR,
        message: 'generic-error',
      };
  }
};

export { useAvailableTimeSlots };

