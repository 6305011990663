import { useEffect, useState } from 'react';
import {
  IProductInfo,
  ProductType,
  getEcommerceCatalog,
  formatDxlCosts,
} from '@vfit/business-data-access';
import {
  errorManager,
  ErrorAction,
  ErrorSeverity,
  useHandleQueriesWithAuthSSO,
} from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { PAGES } from '@vfit/shared/data-access';

interface IElementsCatalog {
  dxlId?: string;
  productType?: ProductType;
}

const useGetProductEcommerceCatalogAssociated = (elements: IElementsCatalog[]) => {
  const [isLoadingEcomm, setIsLoadingEcomm] = useState(true);
  const queries: UseQueryConfig[] = [];
  elements.forEach((item) => {
    queries.push({
      queryKey: [item.dxlId, 'ecommerceCatalogCoreOfferAssociated'],
      queryFn: () =>
        getEcommerceCatalog({
          productId: item.dxlId,
          productType: item.productType,
        }),
      options: {
        onSuccess: (data) => formatDxlCosts(data),
        onError: (error) => {
          const { status }: any = error;
          const statusNumber = Number(status);
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorCode: statusNumber,
          });
        },
      },
    });
  });

  const queriesResults = useHandleQueriesWithAuthSSO(queries);

  useEffect(() => {
    if (Object.values(queriesResults).some((item: any) => item.status !== 'success')) return;
    if (
      elements.length === Object.values(queriesResults).length &&
      Object.values(queriesResults).every((item: any) => item.status === 'success') &&
      Object.values(queriesResults).every((item: any) => item.data?.productInfo.length === 0)
    ) {
      errorManager.handleError(ErrorSeverity.HIGH, {
        errorAction: ErrorAction.PAGE,
        errorPage: PAGES.OFFER_ERROR,
      });
    } else {
      setIsLoadingEcomm(false);
    }
  }, [queriesResults]);

  const catalog = Object.values(queriesResults)
    .map((query: any) => query.data?.productInfo[0]!)
    .filter(Boolean) as IProductInfo[];

  return {
    catalog,
    isLoadingEcomm,
  };
};

export { useGetProductEcommerceCatalogAssociated };

