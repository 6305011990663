import { LoggerInstance, handleEnv } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { ICMSPage } from '@vfit/business-data-access';
import fetchRetry, {
  delay,
  isDevLocal,
  organizeCMSPages,
  retrievePageNavigation,
} from './staticPathPages.utils';

const getSecondLevelPages = async (page: string) => {
  try {
    const ms = Date.now();
    const apiEnv = handleEnv('NEXT_PUBLIC_CMS');
    const apiPdpPages = `${API.CMS_GET_PAGES_ESHOP_BUSINESS}/${page}/all`;
    const pdpResp = await fetchRetry(
      `${apiEnv}/${isDevLocal ? apiPdpPages : `${apiPdpPages}.json`}?t=${ms}`
    );
    await delay(200);
    const pdpPages: ICMSPage = await pdpResp.json();
    let updateObj;
    if (!pdpPages?.['response']) {
      updateObj = Object.fromEntries(
        Object.entries(pdpPages).map(([key, value]) =>
          // Modify key here
          [`${page}-${key}`, value]
        )
      );
    }
    return updateObj;
  } catch {
    return undefined;
  }
};

const getStaticPathPagesDxl = async () => {
  try{
  LoggerInstance.debug('##   getStaticPathPagesDxl  ## ')

  const ms = Date.now();
  const apiEnv = handleEnv('NEXT_PUBLIC_CMS');
  const apiPagesAll = `${API.CMS_GET_PAGES_ESHOP_BUSINESS}/all`;
  const endpoint = `${apiEnv}/${isDevLocal ? apiPagesAll : `${apiPagesAll}.json`}?t=${ms}`;
  console.log(endpoint)
  const pagesResp = await fetchRetry(endpoint);
  await delay(500);
  const firstLevelPage: ICMSPage = await pagesResp.json();

  let allPages = firstLevelPage;

  const secondLevelPaths = [];
  for (const page of Object.keys(firstLevelPage)) {
    const secondLevelPagesPaths = await getSecondLevelPages(page);
    secondLevelPaths.push({ ...secondLevelPagesPaths });
  }

  if (secondLevelPaths?.length > 0) {
    secondLevelPaths?.forEach((element) => {
      allPages = { ...allPages, ...(element || {}) };
    });
  }

  let paths = organizeCMSPages(allPages);

  // region Filter Page on isShow
  paths = paths?.filter((p) => p.params.isShow);
  // endregion

  // region Retrieve Page navigation
  const pageNavigation = await retrievePageNavigation('business-homepage');
  paths = paths.map((path) => ({
    ...path,
    params: {
      ...path.params,
      elements: {
        ...path.params.elements,
        pageNavigation,
      },
    },
  }));

  return paths;
}catch(e){
  LoggerInstance.error('getStaticPathPagesDxl broken',e)
  return []
}
};

export { getStaticPathPagesDxl };

