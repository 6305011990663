import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IDXLGetProfileResponse } from "./dxlGetProfile.models";

/**
 * @description getProfile, retrieve user data using JWT
 * @returns  {Promise<IDXLCookieLoginResponse>}
 */
export const dxlGetProfile = async () =>
  await dxlClient.get(
    API.DXL_GET_PROFILE,
  ) as Promise<IDXLGetProfileResponse>;

