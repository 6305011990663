/* eslint-disable @typescript-eslint/no-namespace */
import { IAddress, IDeviceInfo, IProductInfo, IResource } from '../getEcommerceCatalog';

export interface IDXLPostCreateShoppingCartResponse {
  id: string;
}

export declare type RequiredKeys<T, K extends keyof T> = T & Required<Pick<T, K>>;
export declare type PartialKeys<T, K extends keyof T> = {
  [P in K]?: T[P];
} & Omit<T, K>;
export declare type RequiredId<
  T extends {
    id?: string;
  }
> = RequiredKeys<T, 'id'>;
export declare type WithId = {
  id: string;
};
export declare type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export enum CatalogOfferType {
  PRODUCT = 'product',
  BUSINESS_SOLUTION = 'businessSolution',
}

// interface of cartItem obj to pass to createShoppingCart as paylod
export declare namespace WebShoppingCart {
  export type BannerPlaceholders = Record<string, string>;
  export enum CartProductBannerType {
    NOT_AVAILABLE = 'not-available',
    NBA_CAMPAIGN = 'nba-campaign',
    INCOMPATIBLE_PRODUCT = 'INCOMPATIBLE_PRODUCT',
    INCOMPATIBLE_PRODUCT_LIST = 'INCOMPATIBLE_PRODUCT_LIST',
    WRONG_PAYMENT_METHOD = 'WRONG_PAYMENT_METHOD',
    UPGRADE_NOT_AVAILABLE = 'UPGRADE_NOT_AVAILABLE',
    BS_NOT_STACKED = 'BS_NOT_STACKED',
    UPGRADE_NOT_AVAILABLE_FOR_BS = 'UPGRADE_NOT_AVAILABLE_FOR_BS',
    UPGRADE_NOT_AVAILABLE_FOR_BS_NOT_STACKED = 'UPGRADE_NOT_AVAILABLE_FOR_BS_NOT_STACKED',
    GENERIC_ERROR = 'GENERIC_ERROR',
    FIXED_ALREADY_ADDED = 'FIXED_ALREADY_ADDED',
  }
  interface ISelectedOffer {
    productOfferingId?: string;
    productSpecificationId: string;
    oldProduct: IProductInfo;
    isKeepNumber?: boolean;
    isDiscountAvaiable?: boolean;
  }

  export enum CartItemStatus {
    ACTIVE = 'active',
    REMOVED = 'removed',
  }
  export interface ICartItem {
    id?: string;
    quantity: number;
    status: CartItemStatus;
    type: CatalogOfferType;
    product?: IProductInfo;
    // businessSolution?: IBusinessSolution;
    advTemplateValue?: string;
    coverageAddress?: IAddress;
    coverageNumber?: string | number;
    productBannerType?: CartProductBannerType;
    productBannerPlaceholders?: BannerPlaceholders;
    pushOfferShowed?: boolean;
    // pushBusinessSolution?: IBusinessSolution;
    eligibilityCheckWithCampaign?: boolean;
    adv?: string;
    offerWithPortabilityDiscount?: {
      [productSpecificationId: string]: ISelectedOffer;
    };
  }

  export interface IDevice extends IDeviceInfo {
    selectedResource: IResource;
  }
}

// interface of payload to pass to createShoppingCart
export declare namespace DxlShoppingCart {
  interface IShoppingCart {
    id: string;
    validFor: DXL.IValidFor;
    cartItem: Array<ICartItem>;
    relatedParty: Array<Partial<DXL.IRelatedParty>>;
    contactMedium: Array<Partial<DXL.IContactMedium>>;
    channel?: string;
  }
  interface ICartItemProductProduct {
    id: string;
    productOffering: DXL.IProductOffering;
    productSpecification: DXL.IProductSpecification;
    realizingResource: Array<DXL.IRealizingResource>;
    realizingService: Array<DXL.IRealizingService>;
    productPrice: DXL.IItemPrice[];
  }
  interface ICartItemProduct {
    id: string;
    description: string;
    name: string;
    productSpecification: DXL.IProductSpecification;
    productOffering: DXL.IProductOffering;
    productCharacteristic: Array<DXL.ICharacteristic>;
    product: Array<ICartItemProductProduct>;
  }
  interface ICartItem {
    id: string;
    quantity: number;
    action: string;
    itemPrice: Array<DXL.IItemPrice>;
    productOffering: DXL.IProductOffering;
    status: string;
    product: ICartItemProduct;
  }
  namespace Payloads {
    type ShoppingCart = DeepPartial<IShoppingCart>;
  }
}

export declare namespace DXL {
  export type valueType = string | Array<ICharacteristicValue>;
  export interface ICharacteristicValue {
    value: string;
    id?: string;
  }
  export interface ICharacteristic {
    name: string;
    value?: valueType;
  }
  export interface ICharacteristicMap {
    [name: string]: string;
  }
  export interface IRelatedParty {
    id: string;
    role: string;
    name?: string;
    '@referredType': string;
    characteristic: Array<ICharacteristic>;
  }
  export interface IValidFor {
    startDateTime: string;
    endDateTime: string;
  }
  export interface IContactMediumCharacteristic {
    socialNetworkId?: string;
    city: string;
    country: string;
    emailAddress: string;
    faxNumber: string;
    phoneNumber: string;
    postCode: string;
    stateOrProvince: string;
    street1: string;
    street2: string;
    type: string;
    numberSmsRecontact: string;
    locality?: string;
  }
  export interface IContactMedium {
    '@type': string;
    mediumType: string;
    preferred: boolean;
    characteristic: IContactMediumCharacteristic;
    type: string;
  }
  export interface IProductOffering extends WithId {
    '@referredType'?: string;
    productSpecification?: Array<IProductSpecification>;
  }
  export interface IProductSpecification extends WithId {
    name: string;
    '@referredType'?: string;
    productSpecCharacteristic?: Array<Partial<IProductSpecCharacteristic>>;
  }
  interface IProductSpecCharacteristic {
    name?: string;
    productSpecCharRelationship?: Array<any>;
    productSpecCharacteristicValue?: Array<Partial<ICharacteristicValue>>;
  }
  export interface IRealizingResource extends WithId {
    name?: string;
    '@referredType'?: string;
  }
  export interface IRealizingService extends WithId {
    name?: string;
    characteristic?: Array<Partial<ICharacteristic>>;
  }
  export interface IProductOfferingPrice {
    id: string;
    href: string;
    name: string;
    '@baseType': string;
    '@schemaLocation': string;
    '@type': string;
    '@referredType': string;
  }
  export interface IPriceAlteration {
    applicationDuration: number;
    description: string;
    name: string;
    priceType: string;
    priority: number;
    recurringChargePeriod: string;
    unitOfMeasure: string;
    price: IPrice;
    productOfferingPrice: IProductOfferingPrice;
    '@baseType': string;
    '@schemaLocation': string;
    '@type': string;
  }
  export interface IPrice {
    percentage: number;
    taxRate: number;
    dutyFreeAmount: {
      unit: string;
      value: number;
    };
    taxIncludedAmount: {
      unit: string;
      value: number;
    };
    '@baseType': string;
    '@schemaLocation': string;
    '@type': string;
  }
  export interface IItemPrice {
    description: string;
    name: string;
    priceType: string;
    recurringChargePeriod: string;
    unitOfMeasure: string;
    price: IPrice;
    priceAlteration: Array<IPriceAlteration>;
    productOfferingPrice: IProductOfferingPrice;
    '@baseType': string;
    '@schemaLocation': string;
    '@type': string;
  }

  export interface ICost {
    activationCost: number;
    discountedActivationCost: number;
    renewalCost: number;
    discountedRenewalCost: number;
    renewalNumber?: number | 'unlimited';
    frequency?: number | string;
    discountedPortabilityRenewalCost?: number;
    discountedPortabilityActivationCost?: number;
    version?: string;
  }
}

