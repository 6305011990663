import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IEcommerceCatalogRequest, IEcommerceCatalogResponse } from './getEcommerceCatalog.models';

export const postEcommerceCatalogDSById = (payload: IEcommerceCatalogRequest) =>
  dxlClient.post(API.DXL_GET_ECOMMERCE_CATALOG, payload) as Promise<IEcommerceCatalogResponse>;

export const getEcommerceCatalogDSById = (payload: IEcommerceCatalogRequest) =>
  dxlClient.get(API.DXL_GET_ECOMMERCE_CATALOG, {
    searchParams: { ...payload },
  }) as Promise<IEcommerceCatalogResponse>;
