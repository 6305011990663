import { dxlCloudClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import {
  ISendConfirmationOtpRequest,
  ISendConfirmationOtpResponse,
} from './sendConfirmationOtp.models';

export const postSendConfirmationOtp = (payload: ISendConfirmationOtpRequest) =>
  dxlCloudClient.post(
    API.AWS_SEND_CONFIRMATION_OTP,
    payload
  ) as Promise<ISendConfirmationOtpResponse>;

