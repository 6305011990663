import { SVGProps, memo } from 'react';

const ChevronDown = (props: SVGProps<SVGAElement>): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.3337 10.3333L16.0003 21.6667L4.66699 10.3333"
        stroke={props.color || 'e60000'}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGAElement>>(ChevronDown);
