import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';

interface IPopUpFilter {
  selectedOption: null| boolean;
  setSelectedOption: (value:null|boolean) => void;
}

const initialState: IPopUpFilter = {
  selectedOption: null,
  setSelectedOption: (value:null|boolean) => {},
};

export const PopUpFilterContext = createContext(initialState);

export const PopUpFilterProvider = ({ children }: { children: ReactNode }) => {
  const [selectedOption, setSelectedOption] = useState<null|boolean>(null);

  useEffect(()=>{
    const savedSelectedOption=sessionStorage.getItem("filterPopUpSelection");
    if(savedSelectedOption!==null)setSelectedOption(savedSelectedOption==="true" ? true :false)
    
  },[])

  return (
    <PopUpFilterContext.Provider
      value={{ selectedOption, setSelectedOption: (value) => setSelectedOption(value) }}
    >
      {children}
    </PopUpFilterContext.Provider>
  );
};

