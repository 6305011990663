import { useContext } from 'react';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { useMutation } from 'react-query';
import { HTTPError } from 'ky';
import { PAGES } from '@vfit/shared/data-access';
import {
  ErrorObj,
  OtpCustomDxlErrorMessage,
  VerifyRetrieveDataErrorMessage,
} from './awsHooks.models';
import {
  IVerifyConfirmationOtpTokenRequest,
  postVerifyConfirmationOtpToken,
} from '../../api/verifyConfirmationOtpToken';

const useVerifyConfirmationOtpToken = (
  setHandleOtpIsLoading: (loading: boolean) => void,
  setErrorDetail: (error: ErrorObj) => void
) => {
  const { setIsLoading } = useContext(PageLoadingContext);

  const {
    data,
    mutate,
    error: verifyError,
  } = useMutation(
    'awsVerifyConfirmationOtpToken',
    (payload: IVerifyConfirmationOtpTokenRequest) => {
      setHandleOtpIsLoading(true);
      return postVerifyConfirmationOtpToken(payload);
    },
    {
      onSuccess: () => {
        setHandleOtpIsLoading(false);
        setIsLoading(false);
      },
      onError: async (error: HTTPError) => {
        const resp = (await error?.response?.json().catch(() => ({
          code: 'generic-error',
          message: 'generic-error',
        }))) as {};
        const foundMessage = Object.values(VerifyRetrieveDataErrorMessage).find((message) =>
          resp?.['message']?.includes(message)
        );
        const otpCustomDxlMessage =
          resp?.['message'] === OtpCustomDxlErrorMessage.CHECK_CONTEXT_FAILED
            ? OtpCustomDxlErrorMessage.CHECK_CONTEXT_FAILED
            : '';
        if (!foundMessage && !otpCustomDxlMessage) {
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorPage: PAGES.GENERIC_ERROR,
          });
        } else {
          const errorResp = { ...resp, message: foundMessage || otpCustomDxlMessage };
          setErrorDetail(errorResp);
          setHandleOtpIsLoading(false);
          setIsLoading(false);
        }
      },
    }
  );

  return {
    verifyConfirmationOtpToken: (payload: IVerifyConfirmationOtpTokenRequest) => mutate(payload),
    data,
    verifyError,
  };
};

export { useVerifyConfirmationOtpToken };

