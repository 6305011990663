import {
  ICMSWidgetType,
  IPlaceholderRequest,
  IProductInfo,
  getPriceDescriptionAddons,
  useCmsConfig,
  useSwitchCmsData,
  IConfigCMSParams,
  cmsDataID,
} from '@vfit/business-data-access';
import { IAllDataCMS } from '@vfit/business-data-access';

export const useGetSelectedAddons = (productInfo: IProductInfo, aemData: IAllDataCMS) => {
  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.ADDONS) as IConfigCMSParams;
  const addons = useCmsConfig(cmsConfig, api);

  const { cmsConfig: cmsPlaceholder, api: apiPlaceholder } = useSwitchCmsData(
    cmsDataID.PLACEHOLDER
  ) as IConfigCMSParams;
  const { placeholders } = useCmsConfig(cmsPlaceholder, apiPlaceholder) as IPlaceholderRequest;

  const addonWidget = Object.values(aemData).find(
    (item) => item.type === ICMSWidgetType.SLIDER_ADDON
  );

  if (addonWidget && productInfo && addons) {
    const allAddons = Object.values(addons);
    //add check: if productInfo?.addOn?.productType is 'O' 'C' or 'D'
    addonWidget.elements =
      productInfo?.addOn
        ?.filter((a: IProductInfo) =>
          allAddons.find((addon) => addon.addon?.addonId === a?.productSpecificationId)
        )
        ?.map((item: IProductInfo) => {
          return {
            frontLabel: addons[item?.productSpecificationId!]?.addon?.frontLabel,
            title: addons[item?.productSpecificationId!]?.addon?.addonName,
            action: addons[item?.productSpecificationId!]?.addon?.customAction,
            descriptionHtml: getPriceDescriptionAddons(item, placeholders?.placeholders),
            popupDetail: addons[item?.productSpecificationId!]?.addon?.popupDetail,
            addonId: addons[item?.productSpecificationId!]?.addon?.addonId,
          };
        }) || [];
  }

  return {
    addonWidget,
  };
};

