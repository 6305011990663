import { dxlClient } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IgeographicAddressManagementRequest, IgeographicAddressManagementResponse } from './geographicAddressManagement.models';

export const geographicAddressManagement = (payload: IgeographicAddressManagementRequest) =>
dxlClient.post(
    API.DXL_GET_GEOGRAPHIC_ADDRESS_MANAGEMENT,
    payload
  ) as Promise<IgeographicAddressManagementResponse>;

