import { dxlClientWithOutJwt } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { ICBSSOPayload, ICBSSOResponse } from "./dxlCBSSOLogin.models";

/**
 * @description SSO login, authenticates a user and returns cookies. It also saves the response cookies
 * @param {ICBSSOPayload} auth provide username and password
 * @returns  {Promise<IDXLAnonymousLoginResponse>}
 */
export const dxlCBSSOLogin = async (auth: ICBSSOPayload) =>
  await dxlClientWithOutJwt.post(
    API.DXL_CB_SSO_LOGIN,
    auth
  ) as Promise<ICBSSOResponse>;
