export interface ErrorObj {
  code?: string;
  reason?: string;
  message?: string;
  alertMessage?: string;
}

export enum SendOtpErrorMessage {
  MAX_TOKEN_4PERIOD_EC = 'MAX_TOKEN_4PERIOD_EC',
  MAX_SMS_4DAY_EC = 'MAX_SMS_4DAY_EC',
  MAX_NUM_OTT_VAL_ATT_OVER = 'MAX_NUM_OTT_VAL_ATT_OVER',
  EXP_OTT = 'EXP_OTT',
  OTT_ALR_CONF = 'OTT_ALR_CONF',
  NO_OTT_REQ_FND = 'NO_OTT_REQ_FND',
  REQUEST_IS_NOT_VALID = 'REQUEST IS NOT VALID',
}

export enum VerifyRetrieveDataErrorMessage {
  MAX_NUM_OTT_VAL_ATT_OVER = 'MAX_NUM_OTT_VAL_ATT_OVER',
  EXP_OTT = 'EXP_OTT',
  OTT_ALR_CONF = 'OTT_ALR_CONF',
  INC_OTT = 'INC_OTT',
  REQUEST_IS_NOT_VALID = 'REQUEST IS NOT VALID',
}

export enum OtpCustomDxlErrorMessage {
  CONFIRMATION_ALREADY_DONE = 'CONFIRMATION ALREADY DONE',
  CHECK_CONTEXT_FAILED = 'CHECK CONTEXT FAILED',
}

