import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToFontSize,
  pxToLineHeight,
} from '@vfit/shared/themes';
import { IStyledAccordion, ITitleText } from './accordion.models';

export const StyledAccordion = styled.div<IStyledAccordion>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${({ padding }) => padding || '1rem 0rem'};
  background: ${({ background }) => background || colors.$ffffff};
  border-top: 1px solid;
  border-color: ${({ borderColor }) => borderColor || '#ebebeb'};

  @media (min-width: ${breakpoints.desktop}) {
    padding: ${({ padding, isMenu }) => (isMenu && padding ? padding : `1.844rem 0`)};
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled.h5<ITitleText>`
  font-family: ${({ fontFamily }) => fontFamily || fonts.regular};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${({ fontSize }) => fontSize || pxToFontSize(18)};
  margin: 0;
  cursor: pointer;
  color: ${({ color }) => color || colors.$262626};
  line-height: ${({ lineHeight }) => lineHeight || pxToLineHeight(18, 28)};

  @media (min-width: ${breakpoints.desktop}) {
    font-weight: ${({ fontWeight }) => fontWeight || 400};
    font-style: normal;
    font-size: ${({ isMenu, fontSize }) => (isMenu && fontSize ? fontSize : '24px')};
    line-height: ${({ lineHeight, isMenu }) => (isMenu && lineHeight ? lineHeight : '30px')};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${pxToCssFont(16, 22)}
  font-family: ${fonts.regular};
`;

export const IconSpan = styled.span<{
  color?: string;
  size?: number;
}>`
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.5s;

  &.showing {
    transition: transform 0.5s;
    transform: rotate(-180deg);
  }

  svg {
    width: ${({ size }) => size || 24}px;
    height: auto;

    path {
      stroke: ${({ color }) => color || '#333'};
    }
  }
`;

export const SubtitleText = styled.p`
  margin-bottom: 8px;
  font-weight: 700;
`;

export const ContentText = styled.p`
  margin: 0;
  font-weight: 700;
`;

