import { useContext } from 'react';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { useMutation, useQueryClient } from 'react-query';
import { HTTPError } from 'ky';
import { PAGES } from '@vfit/shared/data-access';
import { OtpCustomDxlErrorMessage, ErrorObj } from './awsHooks.models';
import {
  IEndSerialConfirmationRequest,
  postEndSerialConfirmation,
} from '../../api/endSerialConfirmation';

const useEndSerialConfirmation = (
  setHandleOtpIsLoading: (loading: boolean) => void,
  setErrorDetail: (error: ErrorObj) => void
) => {
  const { setIsLoading } = useContext(PageLoadingContext);
  const queryClient = useQueryClient();

  const { data, mutate } = useMutation(
    'awsEndSerialConfirmation',
    (payload: IEndSerialConfirmationRequest) => {
      setHandleOtpIsLoading(true);
      queryClient.setQueryData('outcome', payload.outcome);
      return postEndSerialConfirmation(payload);
    },
    {
      onSuccess: () => {
        setHandleOtpIsLoading(false);
        setIsLoading(false);
      },
      onError: async (error: HTTPError) => {
        const resp = (await error?.response?.json().catch(() => ({
          code: 'generic-error',
          message: 'generic-error',
        }))) as {};
        const otpCustomDxlMessage = Object.values(OtpCustomDxlErrorMessage).find((message) =>
          resp?.['message']?.includes(message)
        );
        if (!otpCustomDxlMessage) {
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorPage: PAGES.GENERIC_ERROR,
          });
        } else {
          const errorResp = { ...resp, message: otpCustomDxlMessage };
          setErrorDetail(errorResp);
          setHandleOtpIsLoading(false);
          setIsLoading(false);
        }
      },
    }
  );

  return {
    endSerialConfirmation: (payload: IEndSerialConfirmationRequest) => mutate(payload),
    data,
  };
};

export { useEndSerialConfirmation };

