/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum PlaceholdersID {
  OFFER_NAME = 'offerName',
  CORE_OFFER_COST_WITH_FEE = 'coreOfferCostWithFee',
  CORE_OFFER_COST_DISCOUNTED = 'coreOfferCostDiscounted',
  FREE_ACT_RECURRING_COST = 'freeActRecurringCost',
  DISCOUNTED_ACT_RECURRING_COST = 'discountedActRecurringCost',
  ACT_RECURRING_COST_WITH_FEE = 'actRecurringCostWithFee',
  ACT_AND_RECURRING_COST_MAP = 'actAndRecurringCostMap',
  ACT_AND_RECURRING_DISCOUNTED_COST_MAP = 'actAndRecurringDiscountedCostMap',
  FREE_ACT_AND_RECURRING_COST_MAP = 'freeActAndRecurringCostMap',
  FREE_ACT_AND_RECURRING_DISCOUNTED_COST_MAP = 'freeActAndRecurringDiscountedCostMap',
  DISCOUNTED_ACT_RECURRING_COST_MAP = 'discountedActAndRecurringCostMap',
  DISCOUNTED_ACT_RECURRING_DISCOUNTED_COST_MAP = 'discountedActAndRecurringDiscountedCostMap',
  CORE_OFFER_COST_FOR_ADDON = 'coreOfferCostForAddon',
  DISCOUNTED_CORE_OFFER_FOR_ADDON = 'discountedCoreOfferCostForAddon',
  CORE_OFFER_COST_WITH_FEE_RESPONSIVE = 'coreOfferCostWithFeeResponsive',
  CORE_OFFER_COST_DISCOUNTED_RESPONSIVE = 'coreOfferCostDiscountedResponsive',
  FREE_ACT_COST = 'freeActCost',
  DISCOUNTED_ACT_COST = 'discountedActCost',
  ACT_COST_WITH_FEE = 'actCostWithFee',
  ACT_COST_WITH_FEE_RESPONSIVE = 'actCostWithFeeResponsive',
  DISCOUNTED_ACT_COST_RESPONSIVE = 'discountedActCostResponsive',
  // device
  DEVICE_OFFER_NAME_LABEL = 'deviceOfferNameLabel',
  PRODUCT_TITLE_DEVICE = 'productTitle',
  DEVICE_COST_WITH_FEE = 'deviceCostWithFee',
  DEVICE_COST_DISCOUNTED = 'deviceCostDiscounted',
  DEVICE_ACT_COST_WITH_FEE = 'deviceActCostWithFee',
  DEVICE_ACT_COST_DISCOUNTED = 'deviceActCostDiscounted',
  DEVICE_ACT_COST_WITH_FEE_CUSTOMIZATION = 'deviceActCostWithFeeCustomization',
  DEVICE_ACT_COST_DISCOUNTED_CUSTOMIZATION = 'deviceActCostDiscountedCustomization',
  TOTAL_COST_WITH_FEE_CUSTOMIZATION = 'totalCostWithFeeCustomization',
  DEVICE_ACT_COST_FREE = 'deviceActCostFree',
  DEVICE_ACT_COST_FREE_CUSTOMIZATION = 'deviceActCostFreeCustomization',
  TOTAL_RENEWAL_COST_DEVICE = 'totalRenewalCostDevice',
  TOTAL_DEVICE_COST = 'totalDeviceCost',
  // DS IOT
  BS_NAME = 'bsName',
  BS_EDITION_NAME = 'productName',
  TOTAL_COST_FREE = 'totalCostFree',
  TOTAL_COST_WITH_FEE = 'totalCostWithFee',
  TOTAL_COST_DISCOUNTED_DS = 'totalCostDiscounted',
  TOTAL_ACT_COST_WITH_FEE = 'totalActCostWithFee',
  DISCOUNTED_ACT_COST_WITH_FEE = 'totalActCostDiscounted',
  TOTAl_ACT_COST_FREE = 'totalActCostFree',
  DISCOUNTED_ACT_COST_FREE = 'totalActCostDiscountedFree',
  TOTAL_COST_UNA_TANTUM = 'totalCostUnaTantum',
  TOTAL_COST_UNA_TANTUM_DISCOUNTED = 'totalCostUnaTantumDiscounted'
}

export enum PlaceholdersMap {
  productName = 'productName',
  renewalCost = 'costs.renewalCost',
  discountedRenewalCost = 'costs.discountedRenewalCost',
  aoRenCost = 'addon.costs.renewalCost',
  discountedAoRenCost = 'addon.costs.discountedRenewalCost',
  discountedAoRenNumber = 'addon.costs.renewalNumber',
  activationCost = 'addon.costs.activationCost',
  discountedActivationCost = 'addon.costs.discountedActivationCost',
  capacity = 'capacity.capacityLabel',
  deviceRenewalCost = 'costs.renewalCost',
  discountedDeviceRenewalCost = 'costs.discountedRenewalCost',
  deviceRenewalNumber = 'costs.renewalNumber',
  deviceActCost = 'costs.activationCost',
  discountedDeviceActCost = 'costs.discountedActivationCost',
  totalActCost = 'costs.renewalCost',
  businessSolutionName = 'businessSolutionName',
  dsActCost = 'costs.activationCost',
  discountedDsActCost = 'costs.discountedActivationCost',
  totalRenewalCost = 'costs.renewalCost',
  totalCost = 'costs.renewalCost'
}

export enum DeviceDetailsPlaceholders {
  MOBILEPHONE_PLAN = '$mobilephonePlan',
  RENEWAL_COST_DEVICE = '$renewalCostDevice',
  TOTAL_RENEWAL_COST_DEVICE = '$totalRenewalCostDevice',
  DEVICE_COST_UNA_TANTUM = '$deviceCostUnaTantum',
  TOTAL_DEVICE_COST = '$totalDeviceCost'
}

export interface IPlaceholderRequest {
  placeholders: { placeholders: IPlaceholder[] };
}

export interface IPlaceholder {
  description: string;
  id: string;
  value: string;
}

export const invalidPlaceholderNumber = [
  '$productName',
  '$discountedAoRenNumber',
  '$deviceRenewalNumber'
];
