import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IAddress, IEcommerceCatalogResponse } from '@vfit/business-data-access';
//import { IProductHub } from '@vfit/business-components';
import { resetData } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import { PAGES } from '@vfit/shared/data-access';

interface IHubPostCoverageProps {
  children: ReactNode;
  pageType: object;
  hubProduct?: any;
}

interface IWidgetFixedHub {
  isHubPostCoverage: boolean;
  hubPostCoverageElements: any[];
}

const initialState: IWidgetFixedHub = {
  isHubPostCoverage: false,
  hubPostCoverageElements: [],
};

export const HubPostCoverageContext = createContext(initialState);

export const HubPostCoverageContextProvider = ({
  children,
  pageType,
  hubProduct,
}: IHubPostCoverageProps) => {
  const queryClient = useQueryClient();
  const { push } = useRouter();
  const [coverageAddress, setCoverageAddress] = useState<IAddress | null>(null);

  const [hubPostCoverageElements, setHubPostCoverageElements] = useState(
    initialState.hubPostCoverageElements
  );


  const isHubPostCoverage = pageType['isHubPostCoverage'] === 'true';
  const elements = hubProduct?.elements;

  useEffect(() => {
    setCoverageAddress(JSON.parse(localStorage.getItem('valid_address') || '{}'));

    if (isHubPostCoverage) {
      const postCoverageCatalog = JSON.parse(
        localStorage.getItem('catalog_address') || ''
      ) as IEcommerceCatalogResponse;

      const postCoverageElements = elements?.filter((el: any) => {
        const productId = postCoverageCatalog?.productInfo?.find(
          (item) => item.productSpecificationId === el.dxlId
        )?.productSpecificationId;
        return el?.isEditorialCard === 'true' || !!productId;
      });

      resetData(queryClient, ['ecommerceCatalogWithAddress']);
      setHubPostCoverageElements(postCoverageElements);
    }
  }, []);

  const value = useMemo(
    () => ({ isHubPostCoverage, hubPostCoverageElements }),
    [isHubPostCoverage, hubPostCoverageElements]
  );

  if (isHubPostCoverage && coverageAddress && !Object.keys(coverageAddress).length) {
    push(PAGES.BUSINESS_FIXED_LINE);
    return null;
  }
  return (
    <HubPostCoverageContext.Provider value={value}>{children}</HubPostCoverageContext.Provider>
  );
};

