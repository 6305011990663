import { useContext } from 'react';
import { useMutation } from 'react-query';
import { PAGES } from '@vfit/shared/data-access';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { HTTPError } from 'ky';
import { IScheduleRequest, awsSchedule } from '../../api/schedule';

const useSchedule = (isOperator: boolean, redirectToTyp: () => void) => {
  const { setIsLoading } = useContext(PageLoadingContext);

  const { mutate, isLoading } = useMutation(
    'awsSchedule',
    (payload: IScheduleRequest) => awsSchedule(payload),
    {
      onSuccess: redirectToTyp,
      onError: async (error: HTTPError) => {
        const resp = await error.response.json().catch(() => ({
          code: 'generic-error',
        }));
        const { errorPage, message } = getErrorPage(resp?.['code'], isOperator);
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorPage,
          message,
        });
      },
    }
  );

  if (isLoading) setIsLoading(true);

  return {
    confirmSlotTime: (payload: IScheduleRequest) => mutate(payload),
  };
};

const getErrorPage = (error: string, isOperator?: boolean) => {
  const code = error?.split(':')?.pop();

  switch (code) {
    case 'slot-not-available':
      return {
        errorPage: PAGES.SLOT_NOT_AVAILABLE_ERROR,
        message: 'slot-not-available-error',
      };
    case 'time-expired':
      return {
        errorPage: isOperator ? PAGES.TIME_EXPIRED_OPERATOR_ERROR : PAGES.TIME_EXPIRED_ERROR,
        message: 'time-expired',
      };
    case 'appointment-no-longer-schedulable':
      return {
        errorPage: isOperator
          ? PAGES.SCHEDULED_APPOINTMENT_OPERATOR_ERROR
          : PAGES.SCHEDULED_APPOINTMENT_ERROR,
        message: 'appointment-no-longer-schedulable',
      };
    default:
      return {
        errorPage: PAGES.GENERIC_ERROR,
        message: 'generic-error',
      };
  }
};

export { useSchedule };

