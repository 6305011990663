import { IActionSlide, IActionStyle, IActionCustom, IDetail } from '@vfit/shared/models';

export enum IOfferType {
  FIXED_LINE = 'FIXED_LINE',
  MOBILE_LINE = 'MOBILE_LINE',
}

// widget type
export enum ICMSWidgetType {
  FAQ = 'FAQ',
  SPLITTED_IMAGE_TEXT = 'SPLITTED_IMAGE_TEXT',
  SLIDER_LARGE_SMALL = 'SLIDER_LARGE_SMALL',
  SLIDER_PRODUCTS_HUB_BUSINESS = 'SLIDER_PRODUCTS_HUB_BUSINESS',
  PRODUCT_HERO_BANNER = 'PRODUCT_HERO_BANNER',
  SLIDER_ICON_TITLE_DESCRIPTION = 'SLIDER_ICON_TITLE_DESCRIPTION',
  OFFER_MAP_SHOPPING_CART = 'OFFER_MAP_SHOPPING_CART',
  SHOPPING_CART_TECHNOLOGIES = 'SHOPPING_CART_TECHNOLOGIES',
  SLIDER_IMAGE_TITLE_DESCRIPTION = 'SLIDER_IMAGE_TITLE_DESCRIPTION',
  SLIDER_ADDON = 'SLIDER_ADDON',
  SLIDER_DEFAULT = 'SLIDER_DEFAULT',
  HUB_DEVICE = 'HUB_DEVICE',
  TEXT = 'TEXT',
  DEVICE_HERO_BANNER = 'DEVICE_HERO_BANNER',
  SPLITTED_TABLE_TEXT = 'SPLITTED_TABLE_TEXT',
  HUB_DS = 'HUB_DS',
  DS_HERO_BANNER = 'DS_HERO_BANNER',
  TOP_HERO = 'TOP_HERO',
  TABS = 'TABS',
  SEARCH_NATION = 'SEARCH_NATION',
  GUIDED_SELLING_TOOL = 'GUIDED_SELLING_TOOL',
  OPERATOR_PLANS_MODAL = 'OPERATOR_PLANS_MODAL',
  SLIDER_PRODUCT_DS_PDP = 'SLIDER_PRODUCT_DS_PDP',
  EDITORIAL_CARD = 'EDITORIAL_CARD',
  SLOT_CALENDAR = 'SLOT_CALENDAR',
  EDITORIAL_CARD_SLIDER = 'EDITORIAL_CARD_SLIDER',
  SIM_ACTIVATION_OTP = 'SIM_ACTIVATION_OTP',
  SEARCHING_OFFERS_EU = 'SEARCHING_OFFERS_EU',
  SERIAL_CONFIRMATION_OTP = 'SERIAL_CONFIRMATION_OTP',
  COLUMN_ITEMS='COLUMN_ITEMS',
  OFFERS_WORLD='OFFERS_WORLD',
  FULL_BANNER='FULL_BANNER',
  LANDING_ESTERO_POPUP="LANDING_ESTERO_POPUP"
}

export const KEY_DEVICES = {
  POWER_4G: 'power-4g',
};

export interface TrackingOptionsCMS {
  eventCategory: string;
  pageSection: string;
  pageType: string;
  pageSubsection: string;
  parameter?: string;
  event?: string;
  eventLabel?: string;
  journeyType?: string;
  productTargetSegment?: string;
  productCategory?: string | string[];
}

export interface ITaggingCMS {
  eventCategory?: string;
  event?: string;
  pageSection?: string;
  pageType?: string;
  pageSubsection?: string;
  descriptionVolaUrl?: IDescriptionVolaUrl;
  journeyType?: string;
}

export interface IDescriptionVolaUrl {
  environment?: string;
  digitalAsset?: string;
  abTestIdentifier?: string;
  channeltype?: string;
  pageType?: string;
  page?: string;
}

export interface ICoexistenceCMS {
  url: string;
}

export interface IApiCms {
  key: string;
  api: string;
}

export interface ICMSConfigPage {
  apis: IApiCms[];
}
export interface ICMSConfig {
  [key: string]: ICMSConfigPage;
}

export interface IAllDataCMS {
  [key: string]: any;
}

export interface ICoverageToolCongifg {
  coveragetool: {
    isFwaC2C: string;
    hubPostCoverageNoFwaPath: string;
    addressInput: ICardCoverageTool;
    confirmationMap: IConfirmationMap;
    multipleCandidates: ICardCoverageTool;
    errorMessage: IErrorCms;
    geolocalization: ICardCoverageTool;
    koCoverageTool: IKoCoverageTool;
    koTechnicalModal: ICardCoverageTool;
    manualInput: ICardCoverageTool;
    selectInput: ISelectInputCoverageTool;
    lockIn: ILockIn;
  };
  tagging?: ITaggingCMS;
}

export interface IDevices {
  [key: string]: IDeviceItem;
}

export interface IDeviceItem {
  device: ITechnology;
}

export interface ITechs {
  DSL: ITechCard;
  FTTH: ITechCard;
  FTTC: ITechCard;
  FWAOUTDOOR: ITechCard;
  FWAOUTDOOR5G: ITechCard;
  FWAINDOOR: ITechCard;
  FTTHTIM: ITechCard;
  BS: ITechCard;
  TW: ITechCard;
  ULL: ITechCard;
  FTTH_TIM: ITechCard;
}

export interface ITechCard {
  technology: ITechnology;
}

export interface ITechnology {
  id: number;
  title: string;
  detail: IDetail;
  bestOfferChoice: IBestOfferChoice;
  card: ICardDetail;
  visibility: IVisibilityDevice;
  checkoutGa: IChecout;
  checkoutGnp: IChecout;
}

export interface IChecout {
  title: string;
  nextSteps: INextStep[];
}

export interface INextStep {
  description: string;
  detailLinkLabel: string;
  modal: IModalCMS;
}

export interface IModalCMS {
  buttonLabel: string;
  description: string;
  title: string;
  noCode: string;
  linkLabel: string;
  codeNotFound: string;
  message: string;
  list: IList[];
}

export interface IList {
  description: string;
  text: string;
  title: string;
  action: string;
  icon: string;
  detailLinkLabel: string;
  choice: IChoice;
}

export interface IChoice {
  option1: string;
  option2: string;
  option3: string;
}

export interface IVisibilityDevice {
  showInFtth: string;
  showInFttc: string;
  showInAdsl: string;
  showInFwa: string;
  showInFtthTim: string;
  showInFwaIndoor: string;
  showInFwaOutdoor: string;
  showInFwaOutdoor5G: string;
}

export interface ICardDetail {
  buttonLabel: string;
  description: string;
  image: string;
  title: string;
  specs: ISpecs[];
}

export interface ISpecs {
  icon?: string;
  action?: string;
  text?: string;
  description?: string;
  title?: string;
  modalTitle?: string;
  frontTitle?: string;
  modalContent?: string;
}

export interface IBestOfferChoice {
  text: string;
  editAddress: string;
  textForMap: string;
}

export interface ICardCoverageTool {
  description: string;
  button: string;
  buttonStyle: IActionStyle;
  buttonEditAddressStyle: IActionStyle;
  buttonCallsYouStyle: IActionStyle;
  title: string;
  alertError: string;
  buttonCallsYou: string;
  buttonEditAddress: string;
  subtitle: string;
  address: string;
  addressModify: string;
  showOffers: string;
  editLabel: string;
  message: string;
  actionText: string;
  action: IActionSlide;
  clickToCall: IActionSlide;
  customAction: IActionSlide;
}

export interface IKoCoverageTool {
  description: string;
  title: string;
  actions: IActionCustom[];
}

export interface IConfirmationMap {
  buttonEdit: string;
  buttonSubmit: string;
  buttonEditStyle: IActionStyle;
  buttonSubmitStyle: IActionStyle;
}

export interface IErrorCms {
  error0?: string;
  error1?: string;
  error2?: string;
  error3?: string;
  message?: string;
  title?: string;
  notFoundAddress?: string;
}

export interface ISelectInputCoverageTool {
  civicNumber: string;
  address: string;
  municipality: string;
  province: string;
  cap: string;
}

export interface ILockIn {
  idFallbackFromApp: string;
  notAddress: ICardCoverageTool;
  modalErrorNoOffer: ICardCoverageTool;
  modalErrorNoCoverage: ICardCoverageTool;
  modalErrorShoppingCart: ICardCoverageTool;
}
export interface ICardPersonalInfo {
  description: string;
  fcMissingLabel: string;
  title: string;
  url: string;
  subtitle: string;
  buttonLabel: string;
  errorMessage: IErrorCms;
  textAction: string;
  lableFiscalCode: string;
  labelName: string;
  bottomText: string;
}

export interface ICodeResponse {
  description: string;
  alertWarn: string;
  title: string;
  otpError: string;
  alertSuccess: string;
}
export interface IClientRecognition {
  description: string;
  codeNewLabel: string;
  title: string;
  contactNotAccessLabel: string;
  contactNotAccessUrl: string;
  telephoneVerify: string;
  emailVerify: string;
  smsVerify: string;
  codeMissingLabel: string;
  codeNotAccessLabel: string;
  codeResponse: ICodeResponse;
  redirect: {
    buttonLabel: string;
    description: string;
    title: string;
    url: string;
  };
}
export interface IRedirect {
  buttonLabel: string;
  description: string;
  title: string;
  url: string;
}

export interface IClientRegistered {
  description: string;
  checkTelephone: string;
  smallInfo: string;
  checkEmail: string;
  title: string;
  editNumber: string;
}

export interface IClientNumber {
  buttonLabel: string;
  description: string;
  addNewNumberLabel: string;
  title: string;
  errorMessage: IErrorCms;
}

export interface IClientNotLogged {
  description: string;
  missingPasswordLabel: string;
  buttonAccessLabel: string;
  buttonCallLabel: string;
  title: string;
  areYouClientLabel: string;
}

export interface ISelectInput {
  lastName: string;
  docExpirationDate: string;
  birthdate: string;
  fiscalCode: string;
  username: string;
  docNumber: string;
  city: string;
  email: string;
  firstName: string;
  telephone: string;
  contact: string;
  country: string;
  password: string;
  docType: string;
  gender: string;
  nation: string;
  contactPlaceholder: string;
  operator: string;
  migrationCode: string;
  civicNumber: string;
  address: string;
  municipality: string;
  province: string;
  cap: string;
  note: string;
  string: string;
  expirationDate: string;
  cvv: string;
  iban: string;
  type: string;
  user: string;
}
export interface IPersonalInfoCMS {
  personalinfo: {
    generalInfo: ICardPersonalInfo;
    fiscalCode: ICardPersonalInfo;
    clientRecognition: IClientRecognition;
    redirect: IRedirect;
    preFilledDocument: ICardPersonalInfo;
    clientRegistered: IClientRegistered;
    clientLogged: {
      editNumber: IClientNumber;
      newNumber: IClientNumber;
      editEmail: ICardPersonalInfo;
      newEmail: ICardPersonalInfo;
      personalInfo: ICardPersonalInfo;
    };
    clientNotLogged: IClientNotLogged;
    selectInput: ISelectInput;
    lockIn: {
      personalInfo: {
        surnameLabel: string;
        buttonLabel: string;
        description: string;
        title: string;
        nameLabel: string;
      };
      modalNewUser: {
        buttonModifyNumber: string;
        description: string;
        title: string;
        action: IActionSlide;
      };
    };
  };
}

// error
export interface IError {
  description: string;
  id: string;
  title: string;
  actions: IActionCustom[];
}
export interface ICommonErrosCMS {
  commonerrors: Icommonerrors;
}
interface Icommonerrors {
  commonerrors: IError[];
}

export enum ErrorTypes {
  GENERIC_ERROR = 'generic-error',
  SERVICE_ERROR = 'service-error',
  OFFER_ERROR = 'offer-error',
}

// support modules
export interface ISupportModules {
  tagging?: ITaggingCMS;
  whatsapp?: IModule;
}

export interface IModule {
  actions?: IActionCustom[];
  disclaimer?: string;
  disclaimerMobile?: string;
  freeNumberUrl?: string;
  freeNumberLabel?: string;
  description?: string;
  image?: string;
  title?: string;
  footerDescription?: string;
  footerNumberUrl?: string;
  footerIcon?: string;
  footerNumberLabel?: string;
  footerDescriptionMobile?: string;
}

