import { API } from '@vfit/shared/data-access';
import { handleEnv } from '@vfit/shared/data-access';
import { isDevLocal } from '../../cms/staticPathPages/staticPathPages.utils';

const MOBILE_OFFER_LIST = isDevLocal
  ? `${API.CMS_BDL_GET_PRODUCTS}`
  : `${API.CMS_BDL_GET_PRODUCTS}.json`;

/**
 * Retrieve Dati line visibility offers from AEM
 */
const getDatiLineProductSlug = async (): Promise<Array<{ params: { slug: string } }>> => {
  const allProductRes = await fetch(`${handleEnv('NEXT_PUBLIC_CMS')}/${MOBILE_OFFER_LIST}`);
  const allProductId = await allProductRes.json();
  const allSlugs: string[] = [];

  Object.values(allProductId).map((el: any) => {
    const widgetWithSlug: any = Object.values(el).find((w: any) => {
      return w?.type === 'PRODUCT_HERO_BANNER';
    });
    allSlugs.push(widgetWithSlug?.product?.slug);
  });

  return allSlugs.map((fileName) => ({
    params: {
      slug: fileName.replace(/\.md$/, ''),
    },
  }));
};

export { getDatiLineProductSlug};

