import { useContext } from 'react';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { useMutation } from 'react-query';
import { HTTPError } from 'ky';
import { PAGES } from '@vfit/shared/data-access';
import { postVerifyRetrieveData } from '../../api/verifyRetrieveData/verifyRetrieveData';
import { IVerifyRetrieveDataRequest } from '../../api';
import { ErrorObj, VerifyRetrieveDataErrorMessage } from './awsHooks.models';

const useVerifyRetrieveData = (
  setHandleOtpIsLoading: (loading: boolean) => void,
  setErrorDetail: (error: ErrorObj) => void
) => {
  const { setIsLoading } = useContext(PageLoadingContext);

  const {
    data,
    mutate,
    error: verifyError,
  } = useMutation(
    'awsVerifyRetrieveData',
    (payload: IVerifyRetrieveDataRequest) => {
      setHandleOtpIsLoading(true);
      return postVerifyRetrieveData(payload);
    },
    {
      onSuccess: () => {
        setHandleOtpIsLoading(false);
        setIsLoading(false);
      },
      onError: async (error: HTTPError) => {
        const resp = (await error.response.json().catch(() => ({
          code: 'generic-error',
          message: 'generic-error',
        }))) as {};
        const foundMessage = Object.values(VerifyRetrieveDataErrorMessage).find((message) =>
          resp?.['message']?.includes(message)
        );
        if (!foundMessage) {
          errorManager.handleError(ErrorSeverity.HIGH, {
            errorAction: ErrorAction.PAGE,
            errorPage: PAGES.GENERIC_ERROR,
            message: 'generic-error',
          });
        } else {
          const errorResp = { ...resp, message: foundMessage };
          setErrorDetail(errorResp);
          setHandleOtpIsLoading(false);
          setIsLoading(false);
        }
      },
    }
  );

  return {
    verifyRetrieveData: (payload: IVerifyRetrieveDataRequest) => mutate(payload),
    data,
    verifyError,
  };
};

export { useVerifyRetrieveData };

